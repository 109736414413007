.habitats-website-modules-activities-Show {

  .item {

    h2, .h2 {
      font-weight: 800;
    }

    .reservation {
      border: 1px solid $color-border;

      .head {
        background: $color-primary;
        padding: 5px 15px;
        color: white;
        font-size: 26px;
        font-weight: normal;

        @include mb-down(sm) {
          font-size: 18px;
        }
      }

      .content {
        padding: 15px;

        .information {
          ol {
            li {
              span {
                color: $color-primary;
              }
            }
          }
        }
      }
    }

  }

}
