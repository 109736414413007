// MMeny styles
.mm-panels {
    border-right: 1px solid rgba(0, 0, 0, .1) !important;
}

.mm-listitem:after {
    left: 0px !important;
}

.habitats-website-partials-menu-LevelMmenu {
    --mm-color-background: #FFF;

    a.mm-navbar__title {
        font-size: 18px;
    }
}