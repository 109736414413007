
.habitats-website-layouts-main-Header {
	.mmenuToggler {
		color: $color-secondary;
		font-size: 2rem;
		margin-right: 20px;
	}

	.primary-header {
		position: relative;
		width: 100%;

		&:after {
			background-color: $color-primary;
			content: '';
			display: block;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			z-index: -1;
		}

		.contact-items {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 10px 0;
			font-weight: 200;

			li {
				padding-right: 20px;
				color: white;
				font-size: 13px;
				font-weight: bold;

				i {
					color: white;
					font-size: 16px;
					margin-right: 5px;
				}
			}
		}

		.social-items {
			display: flex;
			list-style: none;
			margin-bottom: 0px;

			li {
				i {
					color: white;
					font-size: 25px;
					padding-left: 10px;

				}
			}

			.cart {
				position: relative;
				display: block;
				font-size: 14px;
				font-weight: 800;
				background-color: darken($color-primary, 10%);
				transition: background-color .3s;
				padding: 10px 12px;
				margin-right: 10px;
				text-transform: uppercase;

				.qty {
					position: absolute;
					right: -8px;
					top: 4px;
					background: $color-secondary;
					padding: 0px 5px;
					border-radius: 50%;
					font-size: 12px;
				}

				&:hover {
					text-decoration: none;
					background-color: darken($color-primary, 20%);
				}
			}
		}

		a {
			color: white;

			&.icon {
				margin-top: 6px;
				display: block;
			}

			&.instagram {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: 25px;
				height: 25px;
				background: white;
				border-radius: 50%;
				margin-left: 10px;

				i {
					color: $color-primary;
					font-size: 18px;
					padding: 0;
				}
			}
		}
	}

	.secondary-header {
		margin: 20px 0;
	}

	.mobile-nav {
		background: white; // $color-primary;

		.logo {
			max-width: 80%;
			padding-left: 15px;
		}
	}

	.headerImage {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: black;
			opacity: 0.2;
			pointer-events: none;
		}

		.slider {
			width: 100%;

			.owl-stage {
				display: flex;
			}

			.owl-nav, .owl-dots {
				display: none;
			}

			.slide {
				position: relative;
				padding-bottom: 25%;
				background-size: cover; 
				background-position: center 80%;

				.img-bg-wrapper {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}

				@include mb-down(sm) {
					padding-bottom: 60%;
				}

				.content {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					color: white;
					overflow: hidden;
					padding-bottom: 50px;

					h1, .h1 {
						color: white;
						font-size: 42px;
						text-transform: initial;

						@include mb-down(sm) {
							font-size: 22px;
						}
					}

					p {
						font-weight: 400;
						color: white;
						font-size: 22px;

						@include mb-down(sm) {
							font-size: 14px;
						}
					}
				}
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -90px;
				padding-bottom: 8%;
				background: url(/img/habitats/website/layouts/assets/header.svg);
				background-size: 210% 160%;
				background-position: 50% 0;
			}
		}
	}
}