.common-form-dataset-ItemsTable {

	th {
		color: #2c2b2e;
		font-weight: 400;
		padding: 0 10px 15px 10px;
		vertical-align: top;

		&:nth-child(2) {
			padding-left: 20px;
		}
	}

}