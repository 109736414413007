.common-canvas-blocks-custom-about-Block {
	position: relative;
	margin: 5rem 0 8%;
	background-color: #F4F4F4;
	padding-top: 4rem;

	@include mb-down(sm) {
		padding: 15px 0;
		margin: 1rem 0 0;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding-bottom: 5%;
		transform: translate(0, -50%);
		background-image: url('/img/habitats/website/layouts/assets/long-curve.svg');
		background-size: 150% 100%;
		background-position: 20% 0;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 1px;
		padding-bottom: 5%;
		transform: translate(0, 100%);
		background-image: url(/img/habitats/website/layouts/assets/grey-bottom.svg);
		background-size: 100% 100%;
		z-index: 1;
		background-position: 0 0;
	}

	.container {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 14px;
			left: -30px;
			bottom: 0;
			transform: translate(-100%, 0);
			background-image: url('/img/habitats/website/layouts/assets/birds.svg');
			background-size: contain;
			padding: 5% 4%;
			background-repeat: no-repeat;
		}
	}

	h1, .h1 {
		text-align: center;

		@include mb-down(sm) {
			font-size: 22px;
		}
	}

	p {
		text-align: center;
		color: $color-paragraph;
	}

	a {
		display: table;
		margin: 0 auto;
		text-align: center;
		font-size: 26px;
		padding: 4px 18px;

		@include mb-down(sm) {
			font-size: 18px;
		}
	}
}