.habitats-website-partials-menu-LevelNavbar {
	margin: 0;
	padding: 0;

	.dropdown-toggle:hover + .dropdown-menu {
		display: block !important;
	}

	.dropdown-menu {
		margin-top: -3px;

		&:hover {
			display: block !important;
		}
	}

	li.nav-ite {
		position: relative;
		margin: 0 10px;

		&:last-child {
			margin-right: 0;
		}

		.nav-link {
			font-size: 14px;
			font-weight: 800;
			color: $color-primary;
			text-transform: uppercase;

			&.active {
				color: $color-secondary;
			}

			&:hover {
				color: $color-secondary;
			}
		}

		.dropdown-menu {
			border-radius: 0;
			padding: 0;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

			.dropdown-item {
				border-radius: 0;
				padding: .4rem 1rem !important;
				font-weight: 600;
				color: $color-primary;
				border-bottom: 1px solid #e4e4e4;

				&.active {
					color: white;
					background-color: $color-secondary;
				}

				&:hover {
					color: white;
					background-color: $color-primary;
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}