form {
  .form-group {
    margin-bottom: 10px;

    input, textarea, select {
      //border: none;
      font-size: 20px;
      font-weight: 300;
      font-style: italic;
      padding: 5px 20px;

      &::-webkit-input-placeholder {
        color: $color-placeholder;
      }

      &:-moz-placeholder {
        color: $color-placeholder;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: $color-placeholder;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::placeholder {
        color: $color-placeholder;
      }

      &[type="checkbox"] {
        display: inline-block;
        width: auto;
        height: auto;
        margin: 10px 0 0;
      }
    }

    .input-group {
      .input-group-value {
        width: 100%;
      }

      textarea {
        min-height: 3em;
        max-height: 10em;
      }
    }
  }

  button:not(.ck), input[type="submit"] {
    background: #00B2E2 !important;
    padding: 5px 25px !important;

    &:active, &:focus, &:hover {
      background: darken(#00B2E2, 10%) !important;
    }
  }
}
button {
  outline: none !important;
}
