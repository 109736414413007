.habitats-website-modules-projects-Index {
	.projectGrid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2rem;

		@include mb-down(sm) {
			grid-template-columns: repeat(1, 1fr) !important;
		}

		@media (max-width: 1024px) {
			margin: 1rem 0;
			grid-template-columns: repeat(2, 1fr);
		}
	}
}