.habitats-website-modules-activities-Completed {
    .radius-2 {
        border-radius: 0.5rem;
    }

    .border-top-green {
        border-color: #6cb841;
        border-radius: 0.5rem;
        border-top-width: 8px;
        border-top-style: solid;
    }

    .pt-4\.75 {
        padding-top:1.875rem;
    }
}
