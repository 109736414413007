.common-canvas-blocks-common-leftTextImageRight-Block {
	margin-top: 2.5rem;

	.image {
		display: flex;
		height: 100%;

		.img-bg-wrapper {
			width: 100%;
			height: 100%;
		}
	}
}