.common-canvas-blocks-custom-content-Block {
	margin-bottom: 2.5rem;
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	h1, .h1 {
		@include mb-down(sm) {
			font-size: 22px;
		}
	}
}