.common-canvas-blocks-custom-images-Block {
	margin-top: 2.5rem;
	background-color: #F7F7F7;

	.imagesGrid {
		display: grid;
		margin-bottom: 4rem;
		margin-top: 4rem;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: minmax(0, 250px);
		grid-gap: 2rem;
		min-height: 2.5rem;

		@include mb-only(xs) {
			grid-template-columns: repeat(1, 1fr) !important;
		}

		@include mb-only(sm) {
			grid-template-columns: repeat(2, 1fr) !important;
		}

		@media (max-width: 1024px) {
			grid-gap: 2rem;
		}

        .common-canvas-blocks-utils-image-Block {
            width:      100%;
            height:     100%;

            img {
                object-fit: cover;
                width:      100%;
                height:     100%;
            }
        }
	}
}
