// Colors
$color-primary:               	#6cb841;
$color-secondary:             	#1562a4;
$color-secondary-light:         #b9deff;
$color-error:                   $color-primary;

$color-bg:                      #F4F4F4;
$color-border:                  #ececec;
$color-placeholder:             #DDDDDD;

$color-nav-border:              #DDD;
$color-nav-link:                $color-primary;
$color-nav-link-hover:          $color-primary;
$color-link:                    $color-primary;
$color-link-hover:              $color-primary;

$color-heading:                 #C3B08F;
$color-heading-hover:           #ab9774;
$color-paragraph:               #888888;
$color-paragraph-footer:        #A09D9D;

$color-footer-border:           #E3E3E3;
$color-footer-paragraph:        #A09D9D;

$color-main-bg:                 #F4F5EC;

// Navbar variables
$navbar-height:                 150px;
$navbar-mobile-height:          65px;
