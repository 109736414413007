.habitats-website-modules-cart-Index {
	margin-bottom: 2.5rem;
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.cartGrid {
		margin: 2rem 0;
		background: #f9f9f9;
		border: 1px solid #eaeaea;
		border-radius: 5px;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

		h3 {
			padding: 15px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			margin: 0;
			background: $color-primary;
			color: white;
		}

		.item {
			display: grid;
			grid-template-columns: 50px 100px 1fr 130px 140px 90px;
			grid-auto-rows: 100px;
			place-items: center;
			grid-gap: 10px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			@include mb-down(sm) {
				grid-template-columns: 1fr;
				grid-auto-rows: unset;
				//grid-template-rows: 40px 90px 1fr auto auto;
				grid-gap: 0;
			}

			 a {
				 color: black;
				 text-decoration: none;
			 }

			.actions {
				cursor: pointer;

				@include mb-down(sm) {
					display: flex;
					align-items: center;
					place-self: end;
					padding: 10px;
					color: black;
				}
			}

			.square-padding {
				padding: 10px !important;
			}

			.square {
				border-radius: 5px;
				color: white;
				text-align: center;
				margin: 10px;

				@include mb-down(sm) {
					margin: 0;
				}

				img {
					border-radius: 5px;
				}

				.day {
					font-size: 35px;
					line-height: 1em;
					padding-top: 5px;
				}
			}

			h4 {
				text-transform: initial;
				margin-bottom: 0;
			}

			.description {
				place-self: start;
				overflow: hidden;
				padding: 15px 5px;

				p {
					color: $color-paragraph;
					font-weight: 400;

					@include mb-only(md) {
						max-height: 3em;
						overflow: hidden;
					}

					@include mb-down(sm) {
						margin-bottom: 0;
					}
				}
			}

			.dates {
				text-align: center;
			}

			.amount {
				form {
					> *:not(span) {
						width: 30px;
						height: 30px;
						border-radius: 5px;
						border: none;
						color: white;
						margin: 0 5px;
						padding: 0 !important;
						line-height: 1em;

						&[disabled], &.disabled {
							background: rgba($color-secondary, 0.3) !important;
							cursor: not-allowed;
						}
					}
				}
			}

			.button {
				grid-area: readmore;

				> * {
					display: block;
					width: 100%;
					font-size: 12px;
					border-radius: 5px;
					padding: 3px 10px;
				}
			}

			.dates, .amount, .price {
				@include mb-down(sm) {
					padding: 5px 0;
				}
			}
		}
	}

	.total {
		width: 90px;
		text-align: center;
	}
}