.habitats-website-partials-menu-LevelUl {
	.mm-panel,
	.mm-navbar {
		background: #001d18 !important;
		color: #FFF !important;
		font-family: Arial Black, 'Archivo Black',sans-serif;
		text-transform: uppercase;
	}
	.mm-listitem {
		color: black;
		padding: 0 !important;
	}

	a {
		color: white;
		font-weight: 300 !important;
	}

	.mm-listitem__btn {
		background: $color-primary;
	}
}