.habitats-website-modules-news-Show {
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.informationContainer {
		margin-bottom: 2rem;
	}
}