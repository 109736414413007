// Are you sure you dont need @media($.., ['fit' => 'cover']) ?
// TODO To document.
.img-bg-wrapper {
	@apply overflow-hidden relative;

	> .img-bg {
		@apply h-full w-full absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		object-fit: cover;
	}
}