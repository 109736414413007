.common-form-Disabled {

  // Disable prepend and appends
  .input-group-prepend, .input-group-append {
    display: none;
  }

  // Disable all fields
  input, input:disabled,
  select, select:disabled,
  textarea, textarea:disabled,
  .ck-editor * {
    border: none !important;
    background: none !important;
    padding: 0 !important;
  }

  // Dont show ckeditor toolbar on disabled
  textarea:disabled + .ck-editor {
    .ck-editor__top { display: none; }
  }

}