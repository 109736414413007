.common-canvas-blocks-common-3column-Block {
	h2 {
		@include mb-down(sm) {
			font-size: 22px;
		}
	}
	.img-bg-wrapper {
		width: 100%;
		padding-bottom: 56.25%;
		margin-bottom: 15px;
	}
}