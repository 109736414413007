.common-form-inputs-File {

	embed { height: 500px; width: 350px; }
	img { max-height: 200px; max-width: 300px; }

	.dropify-wrapper { height: 150px; }

	// Dropzone styling
	.dropzoneFiles { display: flex; flex-wrap: wrap; margin-bottom: 15px; margin: 0 -15px 15px;
		.imgBox { display: flex; flex-direction: column; justify-content: center; align-items: center; background: #f1f1f1; margin: 0 15px 15px; padding: 15px;
			img { max-width: 150px; height: auto; margin: auto 15px; }
			span { margin-top: auto; }
		}
	}

}
