.common-canvas-blocks-custom-contact-Block {
	margin: 2.5rem 0;
	color: #9F9F9F;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.iframe-wrapper {
		position: relative;
		height: 350px;
		overflow: hidden;
		margin-top: 2rem;

		.embed-container {
			padding-top: 56.25%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 350px;
				border: 0;
			}
		}
	}
}