.habitats-website-partials-Project {
	position: relative;
	overflow: hidden;

	.project-overlay {
		position: absolute;
		bottom: 0;
		width: 100%;

		svg {
			width: 100%;
			height: auto;
		}
	}

	.project-name {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 5px 20px;
		color: white;
		font-size: 22px;
		font-weight: bold;
		text-align: end;

		@include mb-down(lg) {
			font-size: 20px;
		}

		@media (max-width: 450px) {
			font-size: 16px;
		}
	}
}