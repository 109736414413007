.habitats-website-modules-teachingmaterial-Index {
    position: relative;

    form {
        position: relative;

        .generalConditions {
            position: absolute;
            top: 10px;
            right: 27px;
            text-decoration: none;
            display: flex;
            color: black;

            .question {
                margin-left: 5px;
                background: $color-primary;
                width: 20px;
                height: 20px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                border-radius: 50%;

                &:active, &:focus, &:hover {
                    text-decoration: none;
                    background: darken($color-primary, 10%);
                }
            }
        }
    }

    .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid $color-border;

        .head {
            display: block;
            background: $color-primary;
            padding: 5px 15px;

            h4 {
                color: white;
                margin: 5px 0;
                font-weight: 800;
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        a {
            &:active, &:focus, &:hover {
                text-decoration: none;
            }
        }

        .content {
            display: grid;
            grid-template-rows: 150px 1fr auto;
            width: 100%;
            height: 100%;

            .image {

            }

            p {
                margin: 15px 0 5px;
                padding: 15px;
            }

            .btn {
                margin: 0 auto 15px;
                padding: 5px 25px;
            }
        }
    }

}