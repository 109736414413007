.habitats-website-modules-news-Index {
	margin-bottom: 2.5rem;
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.newsGrid {
		display: grid;
		margin-bottom: 4rem;
		margin-top: 4rem;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2rem;

		@include mb-down(sm) {
			grid-template-columns: repeat(1, 1fr) !important;
		}

		@media (max-width: 1024px) {
			margin: 1rem 0;
			grid-template-columns: repeat(2, 1fr);
		}

		.item {
			display: grid;
			grid-template-areas:
							"date information"
							"readmore information";
			grid-template-columns: 80px 1fr;
			grid-template-rows: 80px 30px;
			grid-gap: 10px;
			margin-bottom: 20px;
			text-decoration: none;
			color: black;
			padding: 10px;

			&:hover {
				background: $color-bg;
			}

			.square-padding {
				padding: 10px !important;
			}

			.square {
				grid-area: date;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background: $color-secondary-light;
				border-radius: 5px;
				color: white;
				text-align: center;

				img {
					border-radius: 5px;
				}

				.day {
					font-size: 35px;
					line-height: 1em;
					padding-top: 5px;
				}
			}

			.button {
				grid-area: readmore;

				> * {
					display: block;
					width: 100%;
					font-size: 12px;
					border-radius: 5px;
					padding: 3px 10px;
				}
			}

			.information {
				grid-area: information;
				overflow: hidden;

				h4 {
					text-transform: initial;
					margin-bottom: 0;
				}

				p {
					color: $color-paragraph;
					font-weight: 400;
				}
			}
		}
	}
}