.habitats-website-partials-Menu {

	> .habitats-website-partials-menu-Level {
		display: flex;

		> li {
			width: 100%;

			> a {
				font-weight: bold;
			}
		}
	}

	&[data-style="mmenu"]:not(.mm-menu) {
		display: none;
	}

	.mm-panel {
		color: $color-primary !important;
		background-color: white;
	}

	.mm-navbar {
		color: white;
		background-color: $color-primary;
	}

	.mm-listitem {
		.mm-btn {
			&:after {
				border-left: 2px solid white;
				border-top: 2px solid white;
			}
		}

		&:after {
			border-color: #efefef !important;
			left: 0 !important
		}
	}
}