.common-form-inputs-Checkbox {

}

.daterangepicker {
  .calendar {
    .calendar-table {
      select {
        background: none;
        border: none;
        font-size: 15px;
        font-weight: 400;
      }

      td {
        border-radius: 0 !important;
        width: 40px;
        height: 29px;
      }

      td.active, td.active:hover {
        background: #009efb;
      }
    }
  }
}