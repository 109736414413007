.habitats-website-partials-Breadcrumbs {
	.breadcrumb {
		background-color: rgba(0, 0, 0, 0);
		margin-bottom: 0;
		padding-bottom: 0;

		ul {
			li {
				font-size: 1.1rem;
				font-weight: bold;

				&:not(:last-child):after {
					content: "\f324";
					font-family: "Font Awesome 5 Pro", serif;
					font-size: 0.7rem;
					margin: 0 15px;
					font-weight: bold;
				}

				&:last-child {
					color: #939393;
				}

				a {
					color: $color-primary;
					text-decoration: none;
				}
			}
		}
	}
}