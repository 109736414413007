.common-form-dataset-ItemTable {

	td {
		padding: 10px 10px;
		vertical-align: top;

		&:nth-child(2) {
			padding-left: 20px;
		}

		&:last-child {
			padding-right: 20px;
		}

		> .form-group:first-child:last-child {
			margin-bottom: 0;
		}
	}
}