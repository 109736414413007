.common-canvas-blocks-custom-stayInTouch-Block {
	position: relative;
	margin: 5rem 0 2.5rem;
	height: 0;
	padding-bottom: 45%;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url('/img/habitats/website/layouts/assets/contact-bg.svg');
		background-size: 150% 110%;
		background-position: 50% 50%;
		z-index: -1;
	}

	@include mb-only(md) {
		margin: 2rem 0 2.5rem;
		padding-bottom: 55%;

		&:before {
			background-size: 180% 110%;
		}
	}

	@include mb-down(sm) {
		padding: 0;
		height: 390px;
		margin: 2rem 0 0;

		&:before {
			background-size: 449% 140%;
			background-position: 50% 22%;
		}
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@include mb-down(sm) {
			margin-top: 1rem;
		}

		.container {
			padding-bottom: 10%;

			@include mb-down(sm) {
				padding: 0;
			}
		}

		h1, .h1 {
			color: white;
			text-align: center;
			margin: 0 0 5px;

			@include mb-down(sm) {
				font-size: 22px;
			}
		}

		p {
			font-weight: 400;
			font-size: 20px;
			color: white;
			text-align: center;
		}

		.btn {
			display: table;
			margin: 0 auto;
		}
	}
}