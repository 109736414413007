.common-canvas-blocks-custom-partners-Block {
	padding: 5rem 0;

	@include mb-down(sm) {
		padding: 1rem 0;
	}

	h2 {
		margin-top: 2rem;
	}

	.partnerGrid {
		overflow: hidden;

		.img-bg-wrapper {
			padding-bottom: 50% !important;
		}

		.owl-stage {
			display: flex;
		}

		.owl-nav {
			&.disabled {
				display: none;
			}
		}

		.owl-dots {
			&.disabled {
				display: none;
			}
		}
	}
}
