@import url('https://fonts.googleapis.com/css?family=Nunito:300i,400,400i,700,800&display=swap');

$font-base:             'Nunito', sans-serif;
$font-head:             $font-base;

body {
	font-family: $font-base;
	font-weight: 400;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
	font-family: $font-head;
	font-size: 1.25rem;
	font-weight: 800;
	margin-bottom: .75rem;
	text-transform: uppercase;
}

h2, .h2 {
	font-size: 2rem;
}