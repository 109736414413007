.common-Media {

	// Is img tag?
	@at-root #{selector-append(img, &)} {

		// Cover fit
		&.cover {
			object-fit: cover;
			object-position: center center;
		}

		// Contain fit
		&.contain {
			object-fit: contain;
			object-position: center center;
		}
	}

	// Is picture tag?
	@at-root #{selector-append(picture, &)} {

		// Cover fit
		&.cover {
			img {
				object-fit: cover;
				object-position: center center;
			}
		}

		// Contain fit
		&.contain {
			img {
				object-fit: contain;
				object-position: center center;
			}
		}
	}
}