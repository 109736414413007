.habitats-website-modules-agenda-Show {
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.informationContainer {
		margin-bottom: 2rem;

		.square {
			width: 80px;
			height: 80px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background: $color-secondary-light;
			border-radius: 5px;
			color: white;
			padding-right: 2px;
			text-align: center;

			img {
				border-radius: 5px;
			}

			.day {
				font-size: 35px;
				line-height: 1em;
				padding-top: 5px;
			}
		}
	}
}