.habitats-website-modules-cart-Show {
	margin-bottom: 2.5rem;
	margin-top: 2.5rem;

	@include mb-down(sm) {
		margin-top: 1rem;
	}

	.cartAddress {
		.radio {
			padding-left: 20px;

			input {
				opacity: 0;
				z-index: 1;
				outline: none;
				padding: 0;

				+ label {
					cursor: pointer;
					display: inline-block;
					padding-left: 5px;
					position: relative;
					font-weight: 400;
					margin-bottom: .5rem;

					&:before {
						content: "";
						-webkit-transition: border 0.5s ease-in-out;
						background-color: #ffffff;
						border-radius: 50%;
						border: 1px solid rgba(120, 130, 140, 0.13);
						display: inline-block;
						height: 17px;
						left: 0;
						margin-left: -20px;
						outline: none;
						position: absolute;
						transition: border 0.5s ease-in-out;
						width: 17px;
						outline: none;
					}

					&:after {
						-moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						-ms-transform: scale(0, 0);
						-o-transform: scale(0, 0);
						-o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						-webkit-transform: scale(0, 0);
						-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						background-color: #263238;
						border-radius: 50%;
						content: " ";
						display: inline-block;
						height: 7px;
						left: 5px;
						margin-left: -20px;
						position: absolute;
						top: 5px;
						transform: scale(0, 0);
						transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						-o-transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
						width: 7px;
					}
				}

				&:checked {
					+ label {
						&:after {
							-ms-transform: scale(1, 1);
							-o-transform: scale(1, 1);
							-webkit-transform: scale(1, 1);
							transform: scale(1, 1);
						}
					}
				}
			}
		}
	}

	.checkbox {
		display: inline-block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 18px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			/* When the checkbox is checked, add a blue background */
			&:checked ~ .checkmark {
				background-color: $color-secondary;

				/* Show the checkmark when checked */
				&:after {
					display: block;
				}
			}
		}

		/* Create a custom checkbox */
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			background-color: #eee;

			/* Create the checkmark/indicator (hidden when not checked) */
			&:after {
				content: "";
				position: absolute;
				display: none;
				left: 9px;
				top: 6px;
				width: 7px;
				height: 10px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}

		/* On mouse-over, add a grey background color */
		&:hover input ~ .checkmark {
			background-color: #ccc;
		}
	}

}