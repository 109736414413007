.common-canvas-blocks-custom-tripleBlock-Block {
  .column {
    &:nth-child(2n) {
      .block {
        .head {
          background: $color-secondary;
        }

        .content {
          .button {
            > * {
              background: $color-secondary;
            }
          }
        }
      }
    }

    .block {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

      .head {
        display: flex;
        align-items: center;
        background: $color-primary;
        padding: 12px 15px;
        color: white;
        font-weight: 800;
        text-transform: uppercase;

        i {
          margin-right: 15px;
          color: white;
          font-size: 1.3em;
        }
      }

      .content {
        padding: 30px 15px 15px;

        .item {
          display: grid;
          grid-template-areas:
                  "date information"
                  "readmore information";
          grid-template-columns: 80px 1fr;
          grid-template-rows: 80px 30px;
          grid-gap: 10px;
          margin-bottom: 20px;
          text-decoration: none;
          color: black;
          padding: 10px;

          &:hover {
            background: $color-bg;
          }

          .square-padding {
            padding: 10px !important;
          }

          .square {
            grid-area: date;
            display: flex;
            background: $color-secondary;
            flex-direction: column;
            justify-content: center;
            border-radius: 5px;
            color: white;
            text-align: center;

            img {
              border-radius: 5px;
            }

            .day {
              font-size: 35px;
              line-height: 1em;
              padding-top: 5px;
            }
          }

          .button {
            grid-area: readmore;

            > * {
              display: block;
              width: 100%;
              font-size: 12px;
              border-radius: 5px;
              padding: 3px 10px;
            }
          }

          .information {
            grid-area: information;
            overflow: hidden;

              .date {
                  color: $color-primary;
              }

            h4 {
              text-transform: initial;
              margin-bottom: 0;
            }

            p {
              color: $color-paragraph;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
