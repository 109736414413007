.error-box {
  min-height: 100vh;
  background-position: center calc(100% - 30px);
}

.error-503 {

  img {
    max-height: 110px;
  }

  h1 {
    font-size: 60px;
    font-weight: 400;
    line-height: 140px;
  }

  h2 {
    font-weight: 400;
  }
}