.common-form-inputs-Switch {
  [class*="switch-"] {

    &.switch-single {
      width: 60px;

      input {
        display: none;
      }

      span {
        min-height: 1em;
        line-height: 1em;
        height: 1.7em;
        overflow: initial;
      }

      a {
        height: 1.9em;
        width: 1.9em;
        top: -2px;
        border: 1px solid #D8D9DB;
      }

      input:checked {
        + span {
          a {
            left: calc(100% + 2px) !important;
          }
        }
      }
    }

    &.switch-multi {
      background-color: transparent;
      border: 1px solid #dedede;
      border-radius: 5px;

      input {
        display: none;

        &:checked + label {
          color: #139df8;
        }
      }

      label {
        height: 2.2em;
        line-height: 2.2em;
      }

      a {
        background-color: rgb(236,236,236);
        color: #139df8;
        border: 0 solid;
        border-radius: 5px;

        &:focus {
          outline: none;
        }
      }
    }

    &.switch-disabled {
      opacity: 0.5;
    }

  }
}