.common-form-inputs-Daterange {
	@include mb-down(sm) {
		font-size: 1.2em;
	}

	.datepicker {
		.ui-datepicker-inline {
			display: flex !important;
			border: none;

			@include mb-down(md) {
				flex-wrap: wrap;
			}

			.ui-datepicker-group {
				border: 1px solid rgba(0,0,0,0.2);
				padding: 15px;

				@include mb-down(sm) {
					width: 100%;
				}

				@include mb-between(sm, lg) {
					margin-bottom: 20px;
				}

				.ui-datepicker-header {
					display: flex;
					background: none;
					border: none;

					.ui-corner-all {
						border: 1px solid rgba(0,0,0,0.2);
						width: 40px;
						text-align: center;

						> * {
							top: 2px;
							left: 0;
						}
					}

					.ui-datepicker-prev {
						position: relative;
						order: -1;

						span {
							position: relative;
							display: inline;
							font-size: 0;
							margin: 0;

							&:before {
								content: "\f060";
								font-family: "Font Awesome 5 Pro";
								font-weight: 400;
								font-size: 15px;
								color: black;

								@include mb-down(sm) {
									font-size: 10px;
								}
							}
						}
					}

					.ui-datepicker-next {
						position: relative;

						span {
							position: relative;
							display: inline;
							font-size: 0;
							margin: 0;

							&:before {
								content: '\f061';
								font-family: "Font Awesome 5 Pro";
								font-weight: 400;
								font-size: 15px;
								color: black;

								@include mb-down(sm) {
									font-size: 10px;
								}
							}
						}
					}

					.ui-datepicker-title {
						margin: 0 auto;
						order: -1;
					}
				}

				.ui-datepicker-calendar {
					tbody {
						td {
							> * {
								padding: 5px 9px;
								background: none;
								border: 1px solid rgba(0, 0, 0, 0.1);
								text-align: center;
								color: black;

								@include mb-only(xs) {
									font-size: 0.8em;
									padding: 3px 6px;
								}

								&.ui-state-highlight {
									background: #80BA26;
									color: white;
								}
							}

							&.dp-highlight {
								> * {
									background: #00B2E2;
									border-color: #00B2E2;
									color: white;
								}
							}

							&.cp-booked {
								> * {
									background: red;
									border-color: red;
									color: white;
								}
							}
						}
					}
				}
			}

			.ui-datepicker-row-break {
				width: auto;
				padding: 15px;
			}
		}
	}

}