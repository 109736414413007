.common-form-Language_icon {
	position: absolute;
	opacity: 0.5;
	right: 30px;
	top: 12px;
	z-index: 49;
}

.input-group {
	.input-group-value {
		position: relative;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		width: 1%;
		margin-bottom: 0;

		.common-form-Language_icon {
			right: 15px;
		}
	}
}