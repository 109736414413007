.habitats-website-layouts-Main {
    .wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow-x: hidden;

        .main {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            > div:not(.fixed-content) {
                flex: 1 0 auto;
            }

            ul {
                padding-left: 20px;
            }

            a:not([class]) {
                color: $color-primary;
            }

            .sidebar {
                h3 {
                    display: block;
                    width: 100%;
                    background: $color-primary;
                    color: white;
                    padding: 15px;
                    font-size: 1.4em;
                    margin: 0;
                }

                nav {
                    > ul {
                        border: 1px solid $color-primary;

                        li {
                            a {
                                display: inline-block;
                                color: black;
                                padding: 5px 15px;
                                font-size: 1.1em;
                            }
                        }
                    }
                }
            }

            // Pagination
            .pagination-wrapper {
                margin: 0 -15px;

                .pagination {
                    padding: 0;

                    .page-item {
                        &.active {
                            .page-link {
                                background: $color-secondary;
                                border-color: $color-secondary;
                                color: white;
                            }
                        }

                        .page-link {
                            color: $color-secondary;
                        }

                    }
                }
            }
        }
    }

    @include mb-up(xl) {
        .container {
            max-width: 1390px !important;
        }
    }

    .fixed-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;

        .toastMaxAmount {
            background: #ffa1a1;
            position: absolute;
            right: 15px;
            bottom: 15px;
            color: white;
            max-width: 300px;
            font-weight: 400;
        }
    }

    .grecaptcha-badge {
        z-index: 1;
    }

    figure.media {
        display: block;
    }
}