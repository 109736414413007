.habitats-website-layouts-main-Footer {
	position: relative;
	margin-top: 30px;
	color: $color-paragraph;

	.img-bg-wrapper {
		padding-bottom: 56.25%;

		img {
			object-fit: contain;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		transform: translate(0, -20%);
		padding-bottom: 8%;
		background-image: url('/img/habitats/website/layouts/assets/footer.svg');
		background-size: 120% 140%;
		background-position: 50% 0;
	}

	.footer-container {
		background-color: $color-bg;

		.container {
			position: relative;
			min-height: 300px;
		}

		.footer-grid {
			display: grid;
			grid-template-columns: 25% 25% 50%;
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;

			@include mb-down(md) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}

			h4 {
				color: $color-paragraph;
				text-transform: initial;
			}

			a {
				color: $color-paragraph;

				i {
					font-size: 40px;
					color: $color-paragraph;
					padding: 5px 0;
				}

				&:nth-child(3) {
					margin-left: 15px;
				}
			}

			.footer-section-menu {
				.nme {
					img {
						max-width: 140px;
						height: 120px;
						margin-top: 10px;
						margin-right: 10px;
						margin-bottom: 10px;
					}

					p {
						font-weight: 400;
						font-style: italic;
						color: $color-paragraph;
						max-width: 45%;
						margin-top: 15px;
					}
				}
			}

			.footer-section-contact {
				ul {
					list-style: none;
					padding: 0;
					font-weight: 300;
				}

				.social-items {
					li {
						a {
							&.instagram {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								width: 40px;
								height: 40px;
								background: $color-paragraph;
								border-radius: 50%;
								margin-left: 10px;

								i {
									color: white;
									font-size: 24px;
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.copyright {
		background-color: $color-primary;
		color: white;
		font-weight: 400;

		@include mb-down(sm) {
			.container {
				flex-wrap: wrap;
			}
		}

		a {
			color: white;
			font-weight: 400;
		}
	}
}