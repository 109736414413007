// Define global typography styles in this document.

// Paragraph styles
p {
	color: black;
	font-weight: 400;
}

// Heading styles
h1, .h1,
.h2, h2 {
	font-size: 46px;
	color: $color-primary;
	margin-bottom: 1.5rem;
	text-transform: initial;
}

.h3 {
	font-size: 36px !important;
	line-height: 130%;
	letter-spacing: 2px;
}

.h4 {
	font-size: 24px !important;
	letter-spacing: 2px;
}

// Button styles
.btn.btn-primary {
	 border: 0;
	 background: $color-primary;
	 font-weight: 800;
	 transition: all .25s ease-in-out;

	 &:active, &:focus, &:hover {
		 background: darken($color-secondary, 10%) !important;
	 }
 }

.btn.btn-secondary {
	border: 0;
	background: $color-secondary;
	font-weight: 800;
	transition: all .25s ease-in-out;

	&:active, &:focus, &:hover {
		background: darken($color-secondary, 10%) !important;
	}
}

// Link styles
a {
	transition: all .25s ease 0s;
}