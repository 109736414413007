@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito:300i,400,400i,700,800&display=swap");
@import "~tailwindcss/dist/components.min.css";
@import "~tailwindcss/dist/utilities.min.css";
.error-box {
  min-height: 100vh;
  background-position: center calc(100% - 30px);
}

.error-503 img {
  max-height: 110px;
}
.error-503 h1 {
  font-size: 60px;
  font-weight: 400;
  line-height: 140px;
}
.error-503 h2 {
  font-weight: 400;
}

body {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  font-family: "Nunito", sans-serif;
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 2rem;
}

.img-bg-wrapper {
  overflow: hidden;
  position: relative;
}
.img-bg-wrapper > img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.mm-panels {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.mm-listitem:after {
  left: 0px !important;
}

.habitats-website-partials-menu-LevelMmenu {
  --mm-color-background: #FFF;
}
.habitats-website-partials-menu-LevelMmenu a.mm-navbar__title {
  font-size: 18px;
}

p {
  color: black;
  font-weight: 400;
}

h1, .h1,
.h2, h2 {
  font-size: 46px;
  color: #6cb841;
  margin-bottom: 1.5rem;
  text-transform: initial;
}

.h3 {
  font-size: 36px !important;
  line-height: 130%;
  letter-spacing: 2px;
}

.h4 {
  font-size: 24px !important;
  letter-spacing: 2px;
}

.btn.btn-primary {
  border: 0;
  background: #6cb841;
  font-weight: 800;
  transition: all 0.25s ease-in-out;
}
.btn.btn-primary:active, .btn.btn-primary:focus, .btn.btn-primary:hover {
  background: #0f4777 !important;
}

.btn.btn-secondary {
  border: 0;
  background: #1562a4;
  font-weight: 800;
  transition: all 0.25s ease-in-out;
}
.btn.btn-secondary:active, .btn.btn-secondary:focus, .btn.btn-secondary:hover {
  background: #0f4777 !important;
}

a {
  transition: all 0.25s ease 0s;
}

form .form-group {
  margin-bottom: 10px;
}
form .form-group input, form .form-group textarea, form .form-group select {
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  padding: 5px 20px;
}
form .form-group input::-webkit-input-placeholder, form .form-group textarea::-webkit-input-placeholder, form .form-group select::-webkit-input-placeholder {
  color: #DDDDDD;
}
form .form-group input:-moz-placeholder, form .form-group textarea:-moz-placeholder, form .form-group select:-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
}
form .form-group input::-moz-placeholder, form .form-group textarea::-moz-placeholder, form .form-group select::-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
}
form .form-group input:-ms-input-placeholder, form .form-group textarea:-ms-input-placeholder, form .form-group select:-ms-input-placeholder {
  color: #DDDDDD;
}
form .form-group input::-ms-input-placeholder, form .form-group textarea::-ms-input-placeholder, form .form-group select::-ms-input-placeholder {
  color: #DDDDDD;
}
form .form-group input::placeholder, form .form-group textarea::placeholder, form .form-group select::placeholder {
  color: #DDDDDD;
}
form .form-group input[type=checkbox], form .form-group textarea[type=checkbox], form .form-group select[type=checkbox] {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 10px 0 0;
}
form .form-group .input-group .input-group-value {
  width: 100%;
}
form .form-group .input-group textarea {
  min-height: 3em;
  max-height: 10em;
}
form button:not(.ck), form input[type=submit] {
  background: #00B2E2 !important;
  padding: 5px 25px !important;
}
form button:not(.ck):active, form button:not(.ck):focus, form button:not(.ck):hover, form input[type=submit]:active, form input[type=submit]:focus, form input[type=submit]:hover {
  background: #008aaf !important;
}

button {
  outline: none !important;
}

.outline-none {
  outline: none !important;
}

.img-bg-wrapper {
  @apply overflow-hidden relative;
}
.img-bg-wrapper > .img-bg {
  @apply h-full w-full absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.common-ResponsiveDebug {
  bottom: 0;
  color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: center;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  top: 5px;
  z-index: 10000;
}
.common-ResponsiveDebug i {
  margin: 0 2px;
}

img.common-Media.cover {
  object-fit: cover;
  object-position: center center;
}
img.common-Media.contain {
  object-fit: contain;
  object-position: center center;
}

picture.common-Media.cover img {
  object-fit: cover;
  object-position: center center;
}
picture.common-Media.contain img {
  object-fit: contain;
  object-position: center center;
}

.common-canvas-Block {
  outline: 10px solid red;
  background: white;
  position: relative;
}
.common-canvas-Block img {
  user-select: none;
}

.fdb-block {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  padding: 7.5rem 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: #ffffff;
}
.fdb-block *,
.fdb-block *::before,
.fdb-block *::after {
  box-sizing: border-box;
}
.fdb-block html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fdb-block article, .fdb-block aside, .fdb-block figcaption, .fdb-block figure, .fdb-block footer, .fdb-block header, .fdb-block hgroup, .fdb-block main, .fdb-block nav, .fdb-block section {
  display: block;
}
.fdb-block body {
  margin: 0;
  font-family: Lato, Helvetica, Arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #444444;
  text-align: left;
  background-color: #fff;
}
.fdb-block [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
.fdb-block hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.fdb-block h1, .fdb-block h2, .fdb-block h3, .fdb-block h4, .fdb-block h5, .fdb-block h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.fdb-block p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.fdb-block abbr[title],
.fdb-block abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
.fdb-block address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.fdb-block ol,
.fdb-block ul,
.fdb-block dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.fdb-block ol ol,
.fdb-block ul ul,
.fdb-block ol ul,
.fdb-block ul ol {
  margin-bottom: 0;
}
.fdb-block dt {
  font-weight: 700;
}
.fdb-block dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.fdb-block blockquote {
  margin: 0 0 1rem;
}
.fdb-block b,
.fdb-block strong {
  font-weight: bolder;
}
.fdb-block small {
  font-size: 80%;
}
.fdb-block sub,
.fdb-block sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.fdb-block sub {
  bottom: -0.25em;
}
.fdb-block sup {
  top: -0.5em;
}
.fdb-block a {
  color: #329ef7;
  text-decoration: none;
  background-color: transparent;
}
.fdb-block a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.fdb-block a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
.fdb-block a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.fdb-block pre,
.fdb-block code,
.fdb-block kbd,
.fdb-block samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.fdb-block pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
.fdb-block figure {
  margin: 0 0 1rem;
}
.fdb-block img {
  vertical-align: middle;
  border-style: none;
}
.fdb-block svg {
  overflow: hidden;
  vertical-align: middle;
}
.fdb-block table {
  border-collapse: collapse;
}
.fdb-block caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.fdb-block th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.fdb-block label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.fdb-block button {
  border-radius: 0;
}
.fdb-block button:focus:not(:focus-visible) {
  outline: 0;
}
.fdb-block input,
.fdb-block button,
.fdb-block select,
.fdb-block optgroup,
.fdb-block textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.fdb-block button,
.fdb-block input {
  overflow: visible;
}
.fdb-block button,
.fdb-block select {
  text-transform: none;
}
.fdb-block [role=button] {
  cursor: pointer;
}
.fdb-block select {
  word-wrap: normal;
}
.fdb-block button,
.fdb-block [type=button],
.fdb-block [type=reset],
.fdb-block [type=submit] {
  -webkit-appearance: button;
}
.fdb-block button:not(:disabled),
.fdb-block [type=button]:not(:disabled),
.fdb-block [type=reset]:not(:disabled),
.fdb-block [type=submit]:not(:disabled) {
  cursor: pointer;
}
.fdb-block button::-moz-focus-inner,
.fdb-block [type=button]::-moz-focus-inner,
.fdb-block [type=reset]::-moz-focus-inner,
.fdb-block [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.fdb-block input[type=radio],
.fdb-block input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.fdb-block textarea {
  overflow: auto;
  resize: vertical;
}
.fdb-block fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.fdb-block legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.fdb-block progress {
  vertical-align: baseline;
}
.fdb-block [type=number]::-webkit-inner-spin-button,
.fdb-block [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.fdb-block [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.fdb-block [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.fdb-block ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.fdb-block output {
  display: inline-block;
}
.fdb-block summary {
  display: list-item;
  cursor: pointer;
}
.fdb-block template {
  display: none;
}
.fdb-block [hidden] {
  display: none !important;
}
.fdb-block h1, .fdb-block h2, .fdb-block h3, .fdb-block h4, .fdb-block h5, .fdb-block h6,
.fdb-block .h1, .fdb-block .h2, .fdb-block .h3, .fdb-block .h4, .fdb-block .h5, .fdb-block .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.5;
}
.fdb-block h1, .fdb-block .h1 {
  font-size: 2.75rem;
}
.fdb-block h2, .fdb-block .h2 {
  font-size: 2rem;
}
.fdb-block h3, .fdb-block .h3 {
  font-size: 1.125rem;
}
.fdb-block h4, .fdb-block .h4 {
  font-size: 1rem;
}
.fdb-block h5, .fdb-block .h5 {
  font-size: 0.875rem;
}
.fdb-block h6, .fdb-block .h6 {
  font-size: 0.813rem;
}
.fdb-block .lead {
  font-size: 1.4rem;
  font-weight: 400;
}
.fdb-block .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.fdb-block .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.fdb-block .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.fdb-block .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.fdb-block hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.fdb-block small,
.fdb-block .small {
  font-size: 0.875em;
  font-weight: 400;
}
.fdb-block mark,
.fdb-block .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.fdb-block .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.fdb-block .list-inline {
  padding-left: 0;
  list-style: none;
}
.fdb-block .list-inline-item {
  display: inline-block;
}
.fdb-block .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.fdb-block .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.fdb-block .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.fdb-block .blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.fdb-block .blockquote-footer::before {
  content: "— ";
}
.fdb-block .form-control {
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 0.25rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .fdb-block .form-control {
    transition: none;
  }
}
.fdb-block .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.fdb-block .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.fdb-block .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.fdb-block .form-control:disabled, .fdb-block .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.fdb-block input[type=date].form-control,
.fdb-block input[type=time].form-control,
.fdb-block input[type=datetime-local].form-control,
.fdb-block input[type=month].form-control {
  appearance: none;
}
.fdb-block select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.fdb-block select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.fdb-block .form-control-file,
.fdb-block .form-control-range {
  display: block;
  width: 100%;
}
.fdb-block .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.fdb-block .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.fdb-block .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.fdb-block .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.fdb-block .form-control-plaintext.form-control-sm, .fdb-block .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.fdb-block .form-control-sm {
  height: calc(1.8125rem + 0.25rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.fdb-block .form-control-lg {
  height: calc(2.875rem + 0.25rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.fdb-block select.form-control[size], .fdb-block select.form-control[multiple] {
  height: auto;
}
.fdb-block textarea.form-control {
  height: auto;
}
.fdb-block .form-group {
  margin-bottom: 1rem;
}
.fdb-block .form-text {
  display: block;
  margin-top: 0.25rem;
}
.fdb-block .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.fdb-block .form-row > .col,
.fdb-block .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.fdb-block .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.fdb-block .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.fdb-block .form-check-input[disabled] ~ .form-check-label, .fdb-block .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.fdb-block .form-check-label {
  margin-bottom: 0;
}
.fdb-block .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.fdb-block .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.fdb-block .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}
.fdb-block .valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .fdb-block .valid-tooltip, .form-row > [class*=col-] > .fdb-block .valid-tooltip {
  left: 5px;
}
.was-validated .fdb-block:valid ~ .valid-feedback,
.was-validated .fdb-block:valid ~ .valid-tooltip, .fdb-block.is-valid ~ .valid-feedback,
.fdb-block.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .fdb-block .form-control:valid, .fdb-block .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .fdb-block .form-control:valid:focus, .fdb-block .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .fdb-block select.form-control:valid, .fdb-block select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated .fdb-block textarea.form-control:valid, .fdb-block textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .fdb-block .custom-select:valid, .fdb-block .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .fdb-block .custom-select:valid:focus, .fdb-block .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .fdb-block .form-check-input:valid ~ .form-check-label, .fdb-block .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .fdb-block .form-check-input:valid ~ .valid-feedback,
.was-validated .fdb-block .form-check-input:valid ~ .valid-tooltip, .fdb-block .form-check-input.is-valid ~ .valid-feedback,
.fdb-block .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .fdb-block .custom-control-input:valid ~ .custom-control-label, .fdb-block .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .fdb-block .custom-control-input:valid ~ .custom-control-label::before, .fdb-block .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .fdb-block .custom-control-input:valid:checked ~ .custom-control-label::before, .fdb-block .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .fdb-block .custom-control-input:valid:focus ~ .custom-control-label::before, .fdb-block .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .fdb-block .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .fdb-block .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .fdb-block .custom-file-input:valid ~ .custom-file-label, .fdb-block .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .fdb-block .custom-file-input:valid:focus ~ .custom-file-label, .fdb-block .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.fdb-block .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.fdb-block .invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .fdb-block .invalid-tooltip, .form-row > [class*=col-] > .fdb-block .invalid-tooltip {
  left: 5px;
}
.was-validated .fdb-block:invalid ~ .invalid-feedback,
.was-validated .fdb-block:invalid ~ .invalid-tooltip, .fdb-block.is-invalid ~ .invalid-feedback,
.fdb-block.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .fdb-block .form-control:invalid, .fdb-block .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .fdb-block .form-control:invalid:focus, .fdb-block .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .fdb-block select.form-control:invalid, .fdb-block select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated .fdb-block textarea.form-control:invalid, .fdb-block textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .fdb-block .custom-select:invalid, .fdb-block .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .fdb-block .custom-select:invalid:focus, .fdb-block .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .fdb-block .form-check-input:invalid ~ .form-check-label, .fdb-block .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .fdb-block .form-check-input:invalid ~ .invalid-feedback,
.was-validated .fdb-block .form-check-input:invalid ~ .invalid-tooltip, .fdb-block .form-check-input.is-invalid ~ .invalid-feedback,
.fdb-block .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .fdb-block .custom-control-input:invalid ~ .custom-control-label, .fdb-block .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .fdb-block .custom-control-input:invalid ~ .custom-control-label::before, .fdb-block .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .fdb-block .custom-control-input:invalid:checked ~ .custom-control-label::before, .fdb-block .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .fdb-block .custom-control-input:invalid:focus ~ .custom-control-label::before, .fdb-block .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .fdb-block .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .fdb-block .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .fdb-block .custom-file-input:invalid ~ .custom-file-label, .fdb-block .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .fdb-block .custom-file-input:invalid:focus ~ .custom-file-label, .fdb-block .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.fdb-block .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.fdb-block .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .fdb-block .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .fdb-block .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .fdb-block .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .fdb-block .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .fdb-block .form-inline .input-group,
  .fdb-block .form-inline .custom-select {
    width: auto;
  }
  .fdb-block .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .fdb-block .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .fdb-block .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .fdb-block .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.fdb-block .btn {
  display: inline-block;
  font-weight: 400;
  color: #444444;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0.125rem solid transparent;
  padding: 0.375rem 1.625rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .fdb-block .btn {
    transition: none;
  }
}
.fdb-block .btn:hover {
  color: #444444;
  text-decoration: none;
}
.fdb-block .btn:focus, .fdb-block .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.fdb-block .btn.disabled, .fdb-block .btn:disabled {
  opacity: 0.65;
}
.fdb-block .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.fdb-block a.btn.disabled,
.fdb-block fieldset:disabled a.btn {
  pointer-events: none;
}
.fdb-block .btn-primary {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7;
}
.fdb-block .btn-primary:hover {
  color: #fff;
  background-color: #0d8df6;
  border-color: #0986ed;
}
.fdb-block .btn-primary:focus, .fdb-block .btn-primary.focus {
  color: #fff;
  background-color: #0d8df6;
  border-color: #0986ed;
  box-shadow: 0 0 0 0.2rem rgba(81, 173, 248, 0.5);
}
.fdb-block .btn-primary.disabled, .fdb-block .btn-primary:disabled {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7;
}
.fdb-block .btn-primary:not(:disabled):not(.disabled):active, .fdb-block .btn-primary:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0986ed;
  border-color: #097fe0;
}
.fdb-block .btn-primary:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 173, 248, 0.5);
}
.fdb-block .btn-secondary {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655;
}
.fdb-block .btn-secondary:hover {
  color: #fff;
  background-color: #fe442f;
  border-color: #fe3822;
}
.fdb-block .btn-secondary:focus, .fdb-block .btn-secondary.focus {
  color: #fff;
  background-color: #fe442f;
  border-color: #fe3822;
  box-shadow: 0 0 0 0.2rem rgba(254, 125, 111, 0.5);
}
.fdb-block .btn-secondary.disabled, .fdb-block .btn-secondary:disabled {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655;
}
.fdb-block .btn-secondary:not(:disabled):not(.disabled):active, .fdb-block .btn-secondary:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fe3822;
  border-color: #fe2d16;
}
.fdb-block .btn-secondary:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 125, 111, 0.5);
}
.fdb-block .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.fdb-block .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.fdb-block .btn-success:focus, .fdb-block .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.fdb-block .btn-success.disabled, .fdb-block .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.fdb-block .btn-success:not(:disabled):not(.disabled):active, .fdb-block .btn-success:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.fdb-block .btn-success:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-success:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.fdb-block .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.fdb-block .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.fdb-block .btn-info:focus, .fdb-block .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.fdb-block .btn-info.disabled, .fdb-block .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.fdb-block .btn-info:not(:disabled):not(.disabled):active, .fdb-block .btn-info:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.fdb-block .btn-info:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-info:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.fdb-block .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.fdb-block .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.fdb-block .btn-warning:focus, .fdb-block .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.fdb-block .btn-warning.disabled, .fdb-block .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.fdb-block .btn-warning:not(:disabled):not(.disabled):active, .fdb-block .btn-warning:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.fdb-block .btn-warning:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.fdb-block .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.fdb-block .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.fdb-block .btn-danger:focus, .fdb-block .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.fdb-block .btn-danger.disabled, .fdb-block .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.fdb-block .btn-danger:not(:disabled):not(.disabled):active, .fdb-block .btn-danger:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.fdb-block .btn-danger:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.fdb-block .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.fdb-block .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.fdb-block .btn-light:focus, .fdb-block .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.fdb-block .btn-light.disabled, .fdb-block .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.fdb-block .btn-light:not(:disabled):not(.disabled):active, .fdb-block .btn-light:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.fdb-block .btn-light:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-light:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.fdb-block .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.fdb-block .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.fdb-block .btn-dark:focus, .fdb-block .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.fdb-block .btn-dark.disabled, .fdb-block .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.fdb-block .btn-dark:not(:disabled):not(.disabled):active, .fdb-block .btn-dark:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.fdb-block .btn-dark:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.fdb-block .btn-outline-primary {
  color: #329ef7;
  border-color: #329ef7;
}
.fdb-block .btn-outline-primary:hover {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7;
}
.fdb-block .btn-outline-primary:focus, .fdb-block .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 158, 247, 0.5);
}
.fdb-block .btn-outline-primary.disabled, .fdb-block .btn-outline-primary:disabled {
  color: #329ef7;
  background-color: transparent;
}
.fdb-block .btn-outline-primary:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #329ef7;
  border-color: #329ef7;
}
.fdb-block .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 158, 247, 0.5);
}
.fdb-block .btn-outline-secondary {
  color: #fe6655;
  border-color: #fe6655;
}
.fdb-block .btn-outline-secondary:hover {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655;
}
.fdb-block .btn-outline-secondary:focus, .fdb-block .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 102, 85, 0.5);
}
.fdb-block .btn-outline-secondary.disabled, .fdb-block .btn-outline-secondary:disabled {
  color: #fe6655;
  background-color: transparent;
}
.fdb-block .btn-outline-secondary:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fe6655;
  border-color: #fe6655;
}
.fdb-block .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 102, 85, 0.5);
}
.fdb-block .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.fdb-block .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.fdb-block .btn-outline-success:focus, .fdb-block .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.fdb-block .btn-outline-success.disabled, .fdb-block .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.fdb-block .btn-outline-success:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-success:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.fdb-block .btn-outline-success:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.fdb-block .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.fdb-block .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.fdb-block .btn-outline-info:focus, .fdb-block .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.fdb-block .btn-outline-info.disabled, .fdb-block .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.fdb-block .btn-outline-info:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-info:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.fdb-block .btn-outline-info:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.fdb-block .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.fdb-block .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.fdb-block .btn-outline-warning:focus, .fdb-block .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.fdb-block .btn-outline-warning.disabled, .fdb-block .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.fdb-block .btn-outline-warning:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.fdb-block .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.fdb-block .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.fdb-block .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.fdb-block .btn-outline-danger:focus, .fdb-block .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.fdb-block .btn-outline-danger.disabled, .fdb-block .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.fdb-block .btn-outline-danger:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.fdb-block .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.fdb-block .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.fdb-block .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.fdb-block .btn-outline-light:focus, .fdb-block .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.fdb-block .btn-outline-light.disabled, .fdb-block .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.fdb-block .btn-outline-light:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-light:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.fdb-block .btn-outline-light:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.fdb-block .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.fdb-block .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.fdb-block .btn-outline-dark:focus, .fdb-block .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.fdb-block .btn-outline-dark.disabled, .fdb-block .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.fdb-block .btn-outline-dark:not(:disabled):not(.disabled):active, .fdb-block .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .fdb-block .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.fdb-block .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .fdb-block .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .fdb-block .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.fdb-block .btn-link {
  font-weight: 400;
  color: #329ef7;
  text-decoration: none;
}
.fdb-block .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.fdb-block .btn-link:focus, .fdb-block .btn-link.focus {
  text-decoration: underline;
}
.fdb-block .btn-link:disabled, .fdb-block .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.fdb-block .btn-lg {
  padding: 0.5rem 1.625rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.fdb-block .btn-sm {
  padding: 0.25rem 1.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.fdb-block .btn-block {
  display: block;
  width: 100%;
}
.fdb-block .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.fdb-block input[type=submit].btn-block,
.fdb-block input[type=reset].btn-block,
.fdb-block input[type=button].btn-block {
  width: 100%;
}
.fdb-block .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.fdb-block .shadow {
  box-shadow: 0 0.3125rem 0.875rem 0 rgba(129, 129, 129, 0.2) !important;
}
.fdb-block .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.fdb-block .shadow-none {
  box-shadow: none !important;
}
.fdb-block h1, .fdb-block h2, .fdb-block h3, .fdb-block h4, .fdb-block h5, .fdb-block h6,
.fdb-block .h1, .fdb-block .h2, .fdb-block .h3, .fdb-block .h4, .fdb-block .h5, .fdb-block .h6 {
  margin-top: 0.3rem;
}
.fdb-block p {
  color: #8892a0;
  font-weight: 400;
}
.fdb-block .rounded {
  overflow: hidden;
}
.fdb-block .rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.fdb-block .container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.fdb-block .container.bg-r {
  background-position: right;
}
.fdb-block.fdb-viewport {
  min-height: calc(100% - 2 * 7.5rem);
}
.fdb-block.fdb-viewport .container {
  min-height: calc(100% - 2 * 7.5rem);
}
.fdb-block.fdb-image-bg {
  color: #f2f2f2;
}
.fdb-block .fdb-box {
  background: #ffffff;
  padding: 3.75rem 2.5rem;
  overflow: hidden;
  color: #444444;
  border-radius: 0.25rem;
  box-shadow: 0 0.3125rem 0.875rem 0 rgba(129, 129, 129, 0.2) !important;
}
.fdb-block .fdb-touch {
  border-top: solid 0.3125rem #329ef7;
  z-index: 2;
}
.fdb-block img + p, .fdb-block img + h3, .fdb-block img + h4 {
  margin-top: 1.25rem;
}
.fdb-block img + h1, .fdb-block img + h2 {
  margin-top: 2.5rem;
}
.fdb-block .col-fill-left {
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.fdb-block .col-fill-left + div {
  position: relative;
  z-index: 2;
}
.fdb-block .col-fill-right {
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.fdb-block .col-fill-right + div {
  position: relative;
  z-index: 2;
}
.fdb-block img.fdb-icon {
  width: auto;
  width: 3.75rem;
}
.fdb-block img.fdb-icon-round {
  width: auto;
  width: 3.75rem;
}
.fdb-block .row-100 {
  height: 6.25rem;
  width: 100%;
}
.fdb-block .row-50 {
  height: 3.125rem;
  width: 100%;
}
.fdb-block .row-70 {
  height: 4.375rem;
  width: 100%;
}
.fdb-block footer {
  padding: 1.875rem 0 !important;
}
.fdb-block footer.footer-large {
  padding: 6.25rem 0 !important;
}
.fdb-block footer .flex-column .nav-link {
  padding-left: 0;
}
.fdb-block footer.bg-dark {
  background: #2d313c;
  color: #f2f2f2;
}
.fdb-block footer.bg-dark a {
  color: #f2f2f2;
}
.fdb-block footer a {
  color: #444444;
}
.fdb-block header {
  background-color: #ffffff;
}
.fdb-block header .navbar-nav a.nav-link {
  color: #777;
}
.fdb-block header .navbar-nav a.nav-link:hover {
  color: #329ef7;
}
.fdb-block header .navbar-nav .active a.nav-link {
  color: #444444;
}
.fdb-block header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.fdb-block header.bg-dark .navbar-nav a.nav-link {
  color: #dcdcdc;
}
.fdb-block header.bg-dark .navbar-nav a.nav-link:hover {
  color: #329ef7;
}
.fdb-block header.bg-dark .navbar-nav .active a.nav-link {
  color: #ffffff;
}
.fdb-block header.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.fdb-block header + header {
  border-top: solid 0.0625rem #EEE;
}
.fdb-block .bg-dark {
  background-color: #2d313c;
  color: #f2f2f2;
}
.fdb-block .bg-dark p.lead {
  color: white;
}
.fdb-block .bg-gray {
  background-color: #f4f7fe;
  color: #444444;
}

@media (max-width: 767.98px) {
  .common-canvas-blocks-common-content-Block h2 {
    font-size: 22px;
  }
}

@media (max-width: 767.98px) {
  .common-canvas-blocks-common-content-Block h2 {
    font-size: 22px;
  }
}

@media (max-width: 767.98px) {
  .common-canvas-blocks-common-3column-Block h2 {
    font-size: 22px;
  }
}
.common-canvas-blocks-common-3column-Block .img-bg-wrapper {
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .common-canvas-blocks-common-3columntext-Block h2 {
    font-size: 22px;
  }
}

.common-canvas-blocks-common-leftImageRightText-Block {
  margin-top: 2.5rem;
}
.common-canvas-blocks-common-leftImageRightText-Block .image {
  display: flex;
  height: 100%;
}
.common-canvas-blocks-common-leftImageRightText-Block .image .img-bg-wrapper {
  width: 100%;
  height: 100%;
}

.common-canvas-blocks-common-leftTextImageRight-Block {
  margin-top: 2.5rem;
}
.common-canvas-blocks-common-leftTextImageRight-Block .image {
  display: flex;
  height: 100%;
}
.common-canvas-blocks-common-leftTextImageRight-Block .image .img-bg-wrapper {
  width: 100%;
  height: 100%;
}

.common-canvas-blocks-custom-about-Block {
  position: relative;
  margin: 5rem 0 8%;
  background-color: #F4F4F4;
  padding-top: 4rem;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-about-Block {
    padding: 15px 0;
    margin: 1rem 0 0;
  }
}
.common-canvas-blocks-custom-about-Block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 5%;
  transform: translate(0, -50%);
  background-image: url("/img/habitats/website/layouts/assets/long-curve.svg");
  background-size: 150% 100%;
  background-position: 20% 0;
}
.common-canvas-blocks-custom-about-Block:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  padding-bottom: 5%;
  transform: translate(0, 100%);
  background-image: url(/img/habitats/website/layouts/assets/grey-bottom.svg);
  background-size: 100% 100%;
  z-index: 1;
  background-position: 0 0;
}
.common-canvas-blocks-custom-about-Block .container {
  position: relative;
}
.common-canvas-blocks-custom-about-Block .container:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -30px;
  bottom: 0;
  transform: translate(-100%, 0);
  background-image: url("/img/habitats/website/layouts/assets/birds.svg");
  background-size: contain;
  padding: 5% 4%;
  background-repeat: no-repeat;
}
.common-canvas-blocks-custom-about-Block h1, .common-canvas-blocks-custom-about-Block .h1 {
  text-align: center;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-about-Block h1, .common-canvas-blocks-custom-about-Block .h1 {
    font-size: 22px;
  }
}
.common-canvas-blocks-custom-about-Block p {
  text-align: center;
  color: #888888;
}
.common-canvas-blocks-custom-about-Block a {
  display: table;
  margin: 0 auto;
  text-align: center;
  font-size: 26px;
  padding: 4px 18px;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-about-Block a {
    font-size: 18px;
  }
}

.common-canvas-blocks-custom-contact-Block {
  margin: 2.5rem 0;
  color: #9F9F9F;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-contact-Block {
    margin-top: 1rem;
  }
}
.common-canvas-blocks-custom-contact-Block .iframe-wrapper {
  position: relative;
  height: 350px;
  overflow: hidden;
  margin-top: 2rem;
}
.common-canvas-blocks-custom-contact-Block .iframe-wrapper .embed-container {
  padding-top: 56.25%;
}
.common-canvas-blocks-custom-contact-Block .iframe-wrapper .embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  border: 0;
}

.common-canvas-blocks-custom-content-Block {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-content-Block {
    margin-top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-content-Block h1, .common-canvas-blocks-custom-content-Block .h1 {
    font-size: 22px;
  }
}

.common-canvas-blocks-custom-images-Block {
  margin-top: 2.5rem;
  background-color: #F7F7F7;
}
.common-canvas-blocks-custom-images-Block .imagesGrid {
  display: grid;
  margin-bottom: 4rem;
  margin-top: 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(0, 250px);
  grid-gap: 2rem;
  min-height: 2.5rem;
}
@media (max-width: 575.98px) {
  .common-canvas-blocks-custom-images-Block .imagesGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .common-canvas-blocks-custom-images-Block .imagesGrid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .common-canvas-blocks-custom-images-Block .imagesGrid {
    grid-gap: 2rem;
  }
}
.common-canvas-blocks-custom-images-Block .imagesGrid .common-canvas-blocks-utils-image-Block {
  width: 100%;
  height: 100%;
}
.common-canvas-blocks-custom-images-Block .imagesGrid .common-canvas-blocks-utils-image-Block img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.common-canvas-blocks-custom-partners-Block {
  padding: 5rem 0;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-partners-Block {
    padding: 1rem 0;
  }
}
.common-canvas-blocks-custom-partners-Block h2 {
  margin-top: 2rem;
}
.common-canvas-blocks-custom-partners-Block .partnerGrid {
  overflow: hidden;
}
.common-canvas-blocks-custom-partners-Block .partnerGrid .img-bg-wrapper {
  padding-bottom: 50% !important;
}
.common-canvas-blocks-custom-partners-Block .partnerGrid .owl-stage {
  display: flex;
}
.common-canvas-blocks-custom-partners-Block .partnerGrid .owl-nav.disabled {
  display: none;
}
.common-canvas-blocks-custom-partners-Block .partnerGrid .owl-dots.disabled {
  display: none;
}

.common-canvas-blocks-custom-stayInTouch-Block {
  position: relative;
  margin: 5rem 0 2.5rem;
  height: 0;
  padding-bottom: 45%;
}
.common-canvas-blocks-custom-stayInTouch-Block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/img/habitats/website/layouts/assets/contact-bg.svg");
  background-size: 150% 110%;
  background-position: 50% 50%;
  z-index: -1;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .common-canvas-blocks-custom-stayInTouch-Block {
    margin: 2rem 0 2.5rem;
    padding-bottom: 55%;
  }
  .common-canvas-blocks-custom-stayInTouch-Block:before {
    background-size: 180% 110%;
  }
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-stayInTouch-Block {
    padding: 0;
    height: 390px;
    margin: 2rem 0 0;
  }
  .common-canvas-blocks-custom-stayInTouch-Block:before {
    background-size: 449% 140%;
    background-position: 50% 22%;
  }
}
.common-canvas-blocks-custom-stayInTouch-Block .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-stayInTouch-Block .content {
    margin-top: 1rem;
  }
}
.common-canvas-blocks-custom-stayInTouch-Block .content .container {
  padding-bottom: 10%;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-stayInTouch-Block .content .container {
    padding: 0;
  }
}
.common-canvas-blocks-custom-stayInTouch-Block .content h1, .common-canvas-blocks-custom-stayInTouch-Block .content .h1 {
  color: white;
  text-align: center;
  margin: 0 0 5px;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-stayInTouch-Block .content h1, .common-canvas-blocks-custom-stayInTouch-Block .content .h1 {
    font-size: 22px;
  }
}
.common-canvas-blocks-custom-stayInTouch-Block .content p {
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-align: center;
}
.common-canvas-blocks-custom-stayInTouch-Block .content .btn {
  display: table;
  margin: 0 auto;
}

.common-canvas-blocks-custom-subpages-Block {
  position: relative;
}
.common-canvas-blocks-custom-subpages-Block .subpagesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 767.98px) {
  .common-canvas-blocks-custom-subpages-Block .subpagesGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .common-canvas-blocks-custom-subpages-Block .subpagesGrid {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
  }
}
.common-canvas-blocks-custom-subpages-Block .subpagesGrid a {
  text-decoration: none;
}
.common-canvas-blocks-custom-subpages-Block .subpagesGrid a .item .img-bg-wrapper {
  height: 210px;
}
.common-canvas-blocks-custom-subpages-Block .subpagesGrid a .item .information h4 {
  background: #6cb841;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-transform: initial;
  text-align: center;
}

.common-canvas-blocks-custom-tripleBlock-Block .column:nth-child(2n) .block .head {
  background: #1562a4;
}
.common-canvas-blocks-custom-tripleBlock-Block .column:nth-child(2n) .block .content .button > * {
  background: #1562a4;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .head {
  display: flex;
  align-items: center;
  background: #6cb841;
  padding: 12px 15px;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .head i {
  margin-right: 15px;
  color: white;
  font-size: 1.3em;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content {
  padding: 30px 15px 15px;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item {
  display: grid;
  grid-template-areas: "date information" "readmore information";
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 30px;
  grid-gap: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
  padding: 10px;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item:hover {
  background: #F4F4F4;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .square-padding {
  padding: 10px !important;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .square {
  grid-area: date;
  display: flex;
  background: #1562a4;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .square img {
  border-radius: 5px;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .button {
  grid-area: readmore;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .button > * {
  display: block;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .information {
  grid-area: information;
  overflow: hidden;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .information .date {
  color: #6cb841;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .information h4 {
  text-transform: initial;
  margin-bottom: 0;
}
.common-canvas-blocks-custom-tripleBlock-Block .column .block .content .item .information p {
  color: #888888;
  font-weight: 400;
}

.common-canvas-blocks-utils-text-Block figure.media > div {
  @apply w-full;
}

.habitats-website-layouts-Main .wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
.habitats-website-layouts-Main .wrapper .main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.habitats-website-layouts-Main .wrapper .main > div:not(.fixed-content) {
  flex: 1 0 auto;
}
.habitats-website-layouts-Main .wrapper .main ul {
  padding-left: 20px;
}
.habitats-website-layouts-Main .wrapper .main a:not([class]) {
  color: #6cb841;
}
.habitats-website-layouts-Main .wrapper .main .sidebar h3 {
  display: block;
  width: 100%;
  background: #6cb841;
  color: white;
  padding: 15px;
  font-size: 1.4em;
  margin: 0;
}
.habitats-website-layouts-Main .wrapper .main .sidebar nav > ul {
  border: 1px solid #6cb841;
}
.habitats-website-layouts-Main .wrapper .main .sidebar nav > ul li a {
  display: inline-block;
  color: black;
  padding: 5px 15px;
  font-size: 1.1em;
}
.habitats-website-layouts-Main .wrapper .main .pagination-wrapper {
  margin: 0 -15px;
}
.habitats-website-layouts-Main .wrapper .main .pagination-wrapper .pagination {
  padding: 0;
}
.habitats-website-layouts-Main .wrapper .main .pagination-wrapper .pagination .page-item.active .page-link {
  background: #1562a4;
  border-color: #1562a4;
  color: white;
}
.habitats-website-layouts-Main .wrapper .main .pagination-wrapper .pagination .page-item .page-link {
  color: #1562a4;
}
@media (min-width: 1200px) {
  .habitats-website-layouts-Main .container {
    max-width: 1390px !important;
  }
}
.habitats-website-layouts-Main .fixed-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}
.habitats-website-layouts-Main .fixed-content .toastMaxAmount {
  background: #ffa1a1;
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: white;
  max-width: 300px;
  font-weight: 400;
}
.habitats-website-layouts-Main .grecaptcha-badge {
  z-index: 1;
}
.habitats-website-layouts-Main figure.media {
  display: block;
}

.habitats-website-layouts-main-Footer {
  position: relative;
  margin-top: 30px;
  color: #888888;
}
.habitats-website-layouts-main-Footer .img-bg-wrapper {
  padding-bottom: 56.25%;
}
.habitats-website-layouts-main-Footer .img-bg-wrapper img {
  object-fit: contain;
}
.habitats-website-layouts-main-Footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translate(0, -20%);
  padding-bottom: 8%;
  background-image: url("/img/habitats/website/layouts/assets/footer.svg");
  background-size: 120% 140%;
  background-position: 50% 0;
}
.habitats-website-layouts-main-Footer .footer-container {
  background-color: #F4F4F4;
}
.habitats-website-layouts-main-Footer .footer-container .container {
  position: relative;
  min-height: 300px;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid {
  display: grid;
  grid-template-columns: 25% 25% 50%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 991.98px) {
  .habitats-website-layouts-main-Footer .footer-container .footer-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid h4 {
  color: #888888;
  text-transform: initial;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid a {
  color: #888888;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid a i {
  font-size: 40px;
  color: #888888;
  padding: 5px 0;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid a:nth-child(3) {
  margin-left: 15px;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid .footer-section-menu .nme img {
  max-width: 140px;
  height: 120px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid .footer-section-menu .nme p {
  font-weight: 400;
  font-style: italic;
  color: #888888;
  max-width: 45%;
  margin-top: 15px;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid .footer-section-contact ul {
  list-style: none;
  padding: 0;
  font-weight: 300;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid .footer-section-contact .social-items li a.instagram {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #888888;
  border-radius: 50%;
  margin-left: 10px;
}
.habitats-website-layouts-main-Footer .footer-container .footer-grid .footer-section-contact .social-items li a.instagram i {
  color: white;
  font-size: 24px;
  padding: 0;
}
.habitats-website-layouts-main-Footer .copyright {
  background-color: #6cb841;
  color: white;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .habitats-website-layouts-main-Footer .copyright .container {
    flex-wrap: wrap;
  }
}
.habitats-website-layouts-main-Footer .copyright a {
  color: white;
  font-weight: 400;
}

.habitats-website-layouts-main-Header .mmenuToggler {
  color: #1562a4;
  font-size: 2rem;
  margin-right: 20px;
}
.habitats-website-layouts-main-Header .primary-header {
  position: relative;
  width: 100%;
}
.habitats-website-layouts-main-Header .primary-header:after {
  background-color: #6cb841;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
.habitats-website-layouts-main-Header .primary-header .contact-items {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 10px 0;
  font-weight: 200;
}
.habitats-website-layouts-main-Header .primary-header .contact-items li {
  padding-right: 20px;
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.habitats-website-layouts-main-Header .primary-header .contact-items li i {
  color: white;
  font-size: 16px;
  margin-right: 5px;
}
.habitats-website-layouts-main-Header .primary-header .social-items {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
}
.habitats-website-layouts-main-Header .primary-header .social-items li i {
  color: white;
  font-size: 25px;
  padding-left: 10px;
}
.habitats-website-layouts-main-Header .primary-header .social-items .cart {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 800;
  background-color: #569234;
  transition: background-color 0.3s;
  padding: 10px 12px;
  margin-right: 10px;
  text-transform: uppercase;
}
.habitats-website-layouts-main-Header .primary-header .social-items .cart .qty {
  position: absolute;
  right: -8px;
  top: 4px;
  background: #1562a4;
  padding: 0px 5px;
  border-radius: 50%;
  font-size: 12px;
}
.habitats-website-layouts-main-Header .primary-header .social-items .cart:hover {
  text-decoration: none;
  background-color: #406d26;
}
.habitats-website-layouts-main-Header .primary-header a {
  color: white;
}
.habitats-website-layouts-main-Header .primary-header a.icon {
  margin-top: 6px;
  display: block;
}
.habitats-website-layouts-main-Header .primary-header a.instagram {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
  margin-left: 10px;
}
.habitats-website-layouts-main-Header .primary-header a.instagram i {
  color: #6cb841;
  font-size: 18px;
  padding: 0;
}
.habitats-website-layouts-main-Header .secondary-header {
  margin: 20px 0;
}
.habitats-website-layouts-main-Header .mobile-nav {
  background: white;
}
.habitats-website-layouts-main-Header .mobile-nav .logo {
  max-width: 80%;
  padding-left: 15px;
}
.habitats-website-layouts-main-Header .headerImage {
  position: relative;
}
.habitats-website-layouts-main-Header .headerImage:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.2;
  pointer-events: none;
}
.habitats-website-layouts-main-Header .headerImage .slider {
  width: 100%;
}
.habitats-website-layouts-main-Header .headerImage .slider .owl-stage {
  display: flex;
}
.habitats-website-layouts-main-Header .headerImage .slider .owl-nav, .habitats-website-layouts-main-Header .headerImage .slider .owl-dots {
  display: none;
}
.habitats-website-layouts-main-Header .headerImage .slider .slide {
  position: relative;
  padding-bottom: 25%;
  background-size: cover;
  background-position: center 80%;
}
.habitats-website-layouts-main-Header .headerImage .slider .slide .img-bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .habitats-website-layouts-main-Header .headerImage .slider .slide {
    padding-bottom: 60%;
  }
}
.habitats-website-layouts-main-Header .headerImage .slider .slide .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  overflow: hidden;
  padding-bottom: 50px;
}
.habitats-website-layouts-main-Header .headerImage .slider .slide .content h1, .habitats-website-layouts-main-Header .headerImage .slider .slide .content .h1 {
  color: white;
  font-size: 42px;
  text-transform: initial;
}
@media (max-width: 767.98px) {
  .habitats-website-layouts-main-Header .headerImage .slider .slide .content h1, .habitats-website-layouts-main-Header .headerImage .slider .slide .content .h1 {
    font-size: 22px;
  }
}
.habitats-website-layouts-main-Header .headerImage .slider .slide .content p {
  font-weight: 400;
  color: white;
  font-size: 22px;
}
@media (max-width: 767.98px) {
  .habitats-website-layouts-main-Header .headerImage .slider .slide .content p {
    font-size: 14px;
  }
}
.habitats-website-layouts-main-Header .headerImage .slider:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -90px;
  padding-bottom: 8%;
  background: url(/img/habitats/website/layouts/assets/header.svg);
  background-size: 210% 160%;
  background-position: 50% 0;
}

.habitats-website-modules-activities-Completed .radius-2 {
  border-radius: 0.5rem;
}
.habitats-website-modules-activities-Completed .border-top-green {
  border-color: #6cb841;
  border-radius: 0.5rem;
  border-top-width: 8px;
  border-top-style: solid;
}
.habitats-website-modules-activities-Completed .pt-4\.75 {
  padding-top: 1.875rem;
}

.habitats-website-modules-activities-Show .border-top-green {
  border-color: #6cb841;
  border-radius: 0.5rem;
  border-top-width: 8px;
  border-top-style: solid;
}

.habitats-website-modules-projects-Index .projectGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-projects-Index .projectGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .habitats-website-modules-projects-Index .projectGrid {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
  }
}

.habitats-website-partials-Breadcrumbs .breadcrumb {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding-bottom: 0;
}
.habitats-website-partials-Breadcrumbs .breadcrumb ul li {
  font-size: 1.1rem;
  font-weight: bold;
}
.habitats-website-partials-Breadcrumbs .breadcrumb ul li:not(:last-child):after {
  content: "\f324";
  font-family: "Font Awesome 5 Pro", serif;
  font-size: 0.7rem;
  margin: 0 15px;
  font-weight: bold;
}
.habitats-website-partials-Breadcrumbs .breadcrumb ul li:last-child {
  color: #939393;
}
.habitats-website-partials-Breadcrumbs .breadcrumb ul li a {
  color: #6cb841;
  text-decoration: none;
}

.habitats-website-partials-Menu > .habitats-website-partials-menu-Level {
  display: flex;
}
.habitats-website-partials-Menu > .habitats-website-partials-menu-Level > li {
  width: 100%;
}
.habitats-website-partials-Menu > .habitats-website-partials-menu-Level > li > a {
  font-weight: bold;
}
.habitats-website-partials-Menu[data-style=mmenu]:not(.mm-menu) {
  display: none;
}
.habitats-website-partials-Menu .mm-panel {
  color: #6cb841 !important;
  background-color: white;
}
.habitats-website-partials-Menu .mm-navbar {
  color: white;
  background-color: #6cb841;
}
.habitats-website-partials-Menu .mm-listitem .mm-btn:after {
  border-left: 2px solid white;
  border-top: 2px solid white;
}
.habitats-website-partials-Menu .mm-listitem:after {
  border-color: #efefef !important;
  left: 0 !important;
}

.habitats-website-partials-menu-LevelNavbar {
  margin: 0;
  padding: 0;
}
.habitats-website-partials-menu-LevelNavbar .dropdown-toggle:hover + .dropdown-menu {
  display: block !important;
}
.habitats-website-partials-menu-LevelNavbar .dropdown-menu {
  margin-top: -3px;
}
.habitats-website-partials-menu-LevelNavbar .dropdown-menu:hover {
  display: block !important;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite {
  position: relative;
  margin: 0 10px;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite:last-child {
  margin-right: 0;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .nav-link {
  font-size: 14px;
  font-weight: 800;
  color: #6cb841;
  text-transform: uppercase;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .nav-link.active {
  color: #1562a4;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .nav-link:hover {
  color: #1562a4;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .dropdown-menu {
  border-radius: 0;
  padding: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .dropdown-menu .dropdown-item {
  border-radius: 0;
  padding: 0.4rem 1rem !important;
  font-weight: 600;
  color: #6cb841;
  border-bottom: 1px solid #e4e4e4;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .dropdown-menu .dropdown-item.active {
  color: white;
  background-color: #1562a4;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .dropdown-menu .dropdown-item:hover {
  color: white;
  background-color: #6cb841;
}
.habitats-website-partials-menu-LevelNavbar li.nav-ite .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.habitats-website-partials-menu-LevelUl .mm-panel,
.habitats-website-partials-menu-LevelUl .mm-navbar {
  background: #001d18 !important;
  color: #FFF !important;
  font-family: Arial Black, "Archivo Black", sans-serif;
  text-transform: uppercase;
}
.habitats-website-partials-menu-LevelUl .mm-listitem {
  color: black;
  padding: 0 !important;
}
.habitats-website-partials-menu-LevelUl a {
  color: white;
  font-weight: 300 !important;
}
.habitats-website-partials-menu-LevelUl .mm-listitem__btn {
  background: #6cb841;
}

.habitats-website-partials-Project {
  position: relative;
  overflow: hidden;
}
.habitats-website-partials-Project .project-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.habitats-website-partials-Project .project-overlay svg {
  width: 100%;
  height: auto;
}
.habitats-website-partials-Project .project-name {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 20px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: end;
}
@media (max-width: 1199.98px) {
  .habitats-website-partials-Project .project-name {
    font-size: 20px;
  }
}
@media (max-width: 450px) {
  .habitats-website-partials-Project .project-name {
    font-size: 16px;
  }
}

.common-form-dataset-ItemCard {
  padding: 5px;
}
.common-form-dataset-ItemCard .card-title {
  flex: 1 0 auto;
}

.common-form-dataset-ItemsCard {
  margin-left: -5px;
  margin-right: -5px;
}

.common-form-dataset-ItemsTable th {
  color: #2c2b2e;
  font-weight: 400;
  padding: 0 10px 15px 10px;
  vertical-align: top;
}
.common-form-dataset-ItemsTable th:nth-child(2) {
  padding-left: 20px;
}

.common-form-dataset-ItemTable td {
  padding: 10px 10px;
  vertical-align: top;
}
.common-form-dataset-ItemTable td:nth-child(2) {
  padding-left: 20px;
}
.common-form-dataset-ItemTable td:last-child {
  padding-right: 20px;
}
.common-form-dataset-ItemTable td > .form-group:first-child:last-child {
  margin-bottom: 0;
}

.common-form-Disabled .input-group-prepend, .common-form-Disabled .input-group-append {
  display: none;
}
.common-form-Disabled input, .common-form-Disabled input:disabled,
.common-form-Disabled select, .common-form-Disabled select:disabled,
.common-form-Disabled textarea, .common-form-Disabled textarea:disabled,
.common-form-Disabled .ck-editor * {
  border: none !important;
  background: none !important;
  padding: 0 !important;
}
.common-form-Disabled textarea:disabled + .ck-editor .ck-editor__top {
  display: none;
}

.daterangepicker .calendar .calendar-table select {
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
}
.daterangepicker .calendar .calendar-table td {
  border-radius: 0 !important;
  width: 40px;
  height: 29px;
}
.daterangepicker .calendar .calendar-table td.active, .daterangepicker .calendar .calendar-table td.active:hover {
  background: #009efb;
}

@media (max-width: 767.98px) {
  .common-form-inputs-Daterange {
    font-size: 1.2em;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline {
  display: flex !important;
  border: none;
}
@media (max-width: 991.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline {
    flex-wrap: wrap;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
}
@media (max-width: 767.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group {
    margin-bottom: 20px;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header {
  display: flex;
  background: none;
  border: none;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-corner-all {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 40px;
  text-align: center;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-corner-all > * {
  top: 2px;
  left: 0;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-prev {
  position: relative;
  order: -1;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-prev span {
  position: relative;
  display: inline;
  font-size: 0;
  margin: 0;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-prev span:before {
  content: "\f060";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 15px;
  color: black;
}
@media (max-width: 767.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-prev span:before {
    font-size: 10px;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-next {
  position: relative;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-next span {
  position: relative;
  display: inline;
  font-size: 0;
  margin: 0;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-next span:before {
  content: "\f061";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 15px;
  color: black;
}
@media (max-width: 767.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-next span:before {
    font-size: 10px;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-header .ui-datepicker-title {
  margin: 0 auto;
  order: -1;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-calendar tbody td > * {
  padding: 5px 9px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  color: black;
}
@media (max-width: 575.98px) {
  .common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-calendar tbody td > * {
    font-size: 0.8em;
    padding: 3px 6px;
  }
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-calendar tbody td > *.ui-state-highlight {
  background: #80BA26;
  color: white;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-calendar tbody td.dp-highlight > * {
  background: #00B2E2;
  border-color: #00B2E2;
  color: white;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-group .ui-datepicker-calendar tbody td.cp-booked > * {
  background: red;
  border-color: red;
  color: white;
}
.common-form-inputs-Daterange .datepicker .ui-datepicker-inline .ui-datepicker-row-break {
  width: auto;
  padding: 15px;
}

.common-form-inputs-File embed {
  height: 500px;
  width: 350px;
}
.common-form-inputs-File img {
  max-height: 200px;
  max-width: 300px;
}
.common-form-inputs-File .dropify-wrapper {
  height: 150px;
}
.common-form-inputs-File .dropzoneFiles {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin: 0 -15px 15px;
}
.common-form-inputs-File .dropzoneFiles .imgBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  margin: 0 15px 15px;
  padding: 15px;
}
.common-form-inputs-File .dropzoneFiles .imgBox img {
  max-width: 150px;
  height: auto;
  margin: auto 15px;
}
.common-form-inputs-File .dropzoneFiles .imgBox span {
  margin-top: auto;
}

.common-form-inputs-ItemSelector .item {
  position: relative;
}
.common-form-inputs-ItemSelector .item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.common-form-inputs-ItemSelector .item label {
  border: 1px solid #c4d8f8;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 transparent, 0 0.25rem 12px 0 transparent;
  transition: border-color 0.3s, box-shadow 0.3s;
  user-select: none;
}
.common-form-inputs-ItemSelector .item label:before {
  left: auto;
  right: 15px;
  top: calc(50% - 8.5px);
}
.common-form-inputs-ItemSelector .item label:after {
  left: auto;
  right: 20px;
  top: calc(50% - 8.5px + 5px);
}
.common-form-inputs-ItemSelector .item label .icon {
  font-size: 30px;
}
.common-form-inputs-ItemSelector .item label img {
  max-height: 30px;
  max-width: 30px;
}
.common-form-inputs-ItemSelector .item input:checked + label,
.common-form-inputs-ItemSelector .item label:hover {
  border-color: #0e8fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.common-form-inputs-Maps .map {
  padding-bottom: 56.25%;
}

.common-form-inputs-Slug .input-group-value {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.common-form-inputs-Slug .input-group-value .common-form-Language_icon {
  right: 15px;
}

.common-form-inputs-Switch [class*=switch-].switch-single {
  width: 60px;
}
.common-form-inputs-Switch [class*=switch-].switch-single input {
  display: none;
}
.common-form-inputs-Switch [class*=switch-].switch-single span {
  min-height: 1em;
  line-height: 1em;
  height: 1.7em;
  overflow: initial;
}
.common-form-inputs-Switch [class*=switch-].switch-single a {
  height: 1.9em;
  width: 1.9em;
  top: -2px;
  border: 1px solid #D8D9DB;
}
.common-form-inputs-Switch [class*=switch-].switch-single input:checked + span a {
  left: calc(100% + 2px) !important;
}
.common-form-inputs-Switch [class*=switch-].switch-multi {
  background-color: transparent;
  border: 1px solid #dedede;
  border-radius: 5px;
}
.common-form-inputs-Switch [class*=switch-].switch-multi input {
  display: none;
}
.common-form-inputs-Switch [class*=switch-].switch-multi input:checked + label {
  color: #139df8;
}
.common-form-inputs-Switch [class*=switch-].switch-multi label {
  height: 2.2em;
  line-height: 2.2em;
}
.common-form-inputs-Switch [class*=switch-].switch-multi a {
  background-color: rgb(236, 236, 236);
  color: #139df8;
  border: 0 solid;
  border-radius: 5px;
}
.common-form-inputs-Switch [class*=switch-].switch-multi a:focus {
  outline: none;
}
.common-form-inputs-Switch [class*=switch-].switch-disabled {
  opacity: 0.5;
}

.common-form-Language_icon {
  position: absolute;
  opacity: 0.5;
  right: 30px;
  top: 12px;
  z-index: 49;
}

.input-group .input-group-value {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group .input-group-value .common-form-Language_icon {
  right: 15px;
}

.habitats-website-modules-activities-Index {
  position: relative;
}
.habitats-website-modules-activities-Index form {
  position: relative;
}
.habitats-website-modules-activities-Index form .generalConditions {
  position: absolute;
  top: 10px;
  right: 27px;
  text-decoration: none;
  display: flex;
  color: black;
}
.habitats-website-modules-activities-Index form .generalConditions .question {
  margin-left: 5px;
  background: #6cb841;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}
.habitats-website-modules-activities-Index form .generalConditions .question:active, .habitats-website-modules-activities-Index form .generalConditions .question:focus, .habitats-website-modules-activities-Index form .generalConditions .question:hover {
  text-decoration: none;
  background: #569234;
}
.habitats-website-modules-activities-Index .item {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
}
.habitats-website-modules-activities-Index .item .head {
  display: block;
  background: #6cb841;
  padding: 5px 15px;
}
.habitats-website-modules-activities-Index .item .head h4 {
  color: white;
  margin: 5px 0;
  font-weight: 800;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.habitats-website-modules-activities-Index .item a:active, .habitats-website-modules-activities-Index .item a:focus, .habitats-website-modules-activities-Index .item a:hover {
  text-decoration: none;
}
.habitats-website-modules-activities-Index .item .content {
  display: grid;
  grid-template-rows: 150px 1fr auto;
  width: 100%;
  height: 100%;
}
.habitats-website-modules-activities-Index .item .content p {
  margin: 15px 0 5px;
  padding: 15px;
}
.habitats-website-modules-activities-Index .item .content .btn {
  margin: 0 auto 15px;
  padding: 5px 25px;
}

.habitats-website-modules-activities-Show .item h2, .habitats-website-modules-activities-Show .item .h2 {
  font-weight: 800;
}
.habitats-website-modules-activities-Show .item .reservation {
  border: 1px solid #ececec;
}
.habitats-website-modules-activities-Show .item .reservation .head {
  background: #6cb841;
  padding: 5px 15px;
  color: white;
  font-size: 26px;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-activities-Show .item .reservation .head {
    font-size: 18px;
  }
}
.habitats-website-modules-activities-Show .item .reservation .content {
  padding: 15px;
}
.habitats-website-modules-activities-Show .item .reservation .content .information ol li span {
  color: #6cb841;
}

.habitats-website-modules-agenda-Index {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agenda-Index {
    margin-top: 1rem;
  }
}
.habitats-website-modules-agenda-Index .agendaGrid {
  position: relative;
  display: grid;
  margin-bottom: 4rem;
  margin-top: 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agenda-Index .agendaGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .habitats-website-modules-agenda-Index .agendaGrid {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
  }
}
.habitats-website-modules-agenda-Index .agendaGrid .item {
  display: grid;
  grid-template-areas: "date information" "readmore information";
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 30px;
  grid-gap: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
  padding: 10px;
}
.habitats-website-modules-agenda-Index .agendaGrid .item:hover {
  background: #F4F4F4;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .square-padding {
  padding: 10px !important;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .square {
  grid-area: date;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #b9deff;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .square img {
  border-radius: 5px;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .button {
  grid-area: readmore;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .button > * {
  display: block;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .information {
  grid-area: information;
  overflow: hidden;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .information h4 {
  text-transform: initial;
  margin-bottom: 0;
}
.habitats-website-modules-agenda-Index .agendaGrid .item .information p {
  color: #888888;
  font-weight: 400;
}

.habitats-website-modules-agenda-Show {
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agenda-Show {
    margin-top: 1rem;
  }
}
.habitats-website-modules-agenda-Show .informationContainer {
  margin-bottom: 2rem;
}
.habitats-website-modules-agenda-Show .informationContainer .square {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #b9deff;
  border-radius: 5px;
  color: white;
  padding-right: 2px;
  text-align: center;
}
.habitats-website-modules-agenda-Show .informationContainer .square img {
  border-radius: 5px;
}
.habitats-website-modules-agenda-Show .informationContainer .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}

.habitats-website-modules-agendamodule-Index {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agendamodule-Index {
    margin-top: 1rem;
  }
}
.habitats-website-modules-agendamodule-Index .agendaGrid {
  position: relative;
  display: grid;
  margin-bottom: 4rem;
  margin-top: 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agendamodule-Index .agendaGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .habitats-website-modules-agendamodule-Index .agendaGrid {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
  }
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item {
  display: grid;
  grid-template-areas: "date information" "readmore information";
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 30px;
  grid-gap: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
  padding: 10px;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item:hover {
  background: #F4F4F4;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .square-padding {
  padding: 10px !important;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .square {
  grid-area: date;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #b9deff;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .square img {
  border-radius: 5px;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .button {
  grid-area: readmore;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .button > * {
  display: block;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .information {
  grid-area: information;
  overflow: hidden;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .information .date {
  color: #6cb841;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .information h4 {
  text-transform: initial;
  margin-bottom: 0;
}
.habitats-website-modules-agendamodule-Index .agendaGrid .item .information p {
  color: #888888;
  font-weight: 400;
}

.habitats-website-modules-agendamodule-Show {
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-agendamodule-Show {
    margin-top: 1rem;
  }
}
.habitats-website-modules-agendamodule-Show .informationContainer {
  margin-bottom: 2rem;
}
.habitats-website-modules-agendamodule-Show .informationContainer .square {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #b9deff;
  border-radius: 5px;
  color: white;
  padding-right: 2px;
  text-align: center;
}
.habitats-website-modules-agendamodule-Show .informationContainer .square img {
  border-radius: 5px;
}
.habitats-website-modules-agendamodule-Show .informationContainer .square .day {
  font-size: 25px;
  line-height: 1em;
  padding-top: 5px;
}

.habitats-website-modules-cart-Index {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index {
    margin-top: 1rem;
  }
}
.habitats-website-modules-cart-Index .cartGrid {
  margin: 2rem 0;
  background: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.habitats-website-modules-cart-Index .cartGrid h3 {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  background: #6cb841;
  color: white;
}
.habitats-website-modules-cart-Index .cartGrid .item {
  display: grid;
  grid-template-columns: 50px 100px 1fr 130px 140px 90px;
  grid-auto-rows: 100px;
  place-items: center;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item {
    grid-template-columns: 1fr;
    grid-auto-rows: unset;
    grid-gap: 0;
  }
}
.habitats-website-modules-cart-Index .cartGrid .item a {
  color: black;
  text-decoration: none;
}
.habitats-website-modules-cart-Index .cartGrid .item .actions {
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item .actions {
    display: flex;
    align-items: center;
    place-self: end;
    padding: 10px;
    color: black;
  }
}
.habitats-website-modules-cart-Index .cartGrid .item .square-padding {
  padding: 10px !important;
}
.habitats-website-modules-cart-Index .cartGrid .item .square {
  border-radius: 5px;
  color: white;
  text-align: center;
  margin: 10px;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item .square {
    margin: 0;
  }
}
.habitats-website-modules-cart-Index .cartGrid .item .square img {
  border-radius: 5px;
}
.habitats-website-modules-cart-Index .cartGrid .item .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}
.habitats-website-modules-cart-Index .cartGrid .item h4 {
  text-transform: initial;
  margin-bottom: 0;
}
.habitats-website-modules-cart-Index .cartGrid .item .description {
  place-self: start;
  overflow: hidden;
  padding: 15px 5px;
}
.habitats-website-modules-cart-Index .cartGrid .item .description p {
  color: #888888;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item .description p {
    max-height: 3em;
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item .description p {
    margin-bottom: 0;
  }
}
.habitats-website-modules-cart-Index .cartGrid .item .dates {
  text-align: center;
}
.habitats-website-modules-cart-Index .cartGrid .item .amount form > *:not(span) {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: white;
  margin: 0 5px;
  padding: 0 !important;
  line-height: 1em;
}
.habitats-website-modules-cart-Index .cartGrid .item .amount form > *:not(span)[disabled], .habitats-website-modules-cart-Index .cartGrid .item .amount form > *:not(span).disabled {
  background: rgba(21, 98, 164, 0.3) !important;
  cursor: not-allowed;
}
.habitats-website-modules-cart-Index .cartGrid .item .button {
  grid-area: readmore;
}
.habitats-website-modules-cart-Index .cartGrid .item .button > * {
  display: block;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Index .cartGrid .item .dates, .habitats-website-modules-cart-Index .cartGrid .item .amount, .habitats-website-modules-cart-Index .cartGrid .item .price {
    padding: 5px 0;
  }
}
.habitats-website-modules-cart-Index .total {
  width: 90px;
  text-align: center;
}

.habitats-website-modules-cart-Show {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-cart-Show {
    margin-top: 1rem;
  }
}
.habitats-website-modules-cart-Show .cartAddress .radio {
  padding-left: 20px;
}
.habitats-website-modules-cart-Show .cartAddress .radio input {
  opacity: 0;
  z-index: 1;
  outline: none;
  padding: 0;
}
.habitats-website-modules-cart-Show .cartAddress .radio input + label {
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.habitats-website-modules-cart-Show .cartAddress .radio input + label:before {
  content: "";
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgba(120, 130, 140, 0.13);
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  outline: none;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
  outline: none;
}
.habitats-website-modules-cart-Show .cartAddress .radio input + label:after {
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #263238;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 7px;
  left: 5px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  transform: scale(0, 0);
  transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 7px;
}
.habitats-website-modules-cart-Show .cartAddress .radio input:checked + label:after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.habitats-website-modules-cart-Show .checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
}
.habitats-website-modules-cart-Show .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  /* When the checkbox is checked, add a blue background */
}
.habitats-website-modules-cart-Show .checkbox input:checked ~ .checkmark {
  background-color: #1562a4;
  /* Show the checkmark when checked */
}
.habitats-website-modules-cart-Show .checkbox input:checked ~ .checkmark:after {
  display: block;
}
.habitats-website-modules-cart-Show .checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  /* Create the checkmark/indicator (hidden when not checked) */
}
.habitats-website-modules-cart-Show .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 6px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.habitats-website-modules-cart-Show .checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.habitats-website-modules-forms-Index input:not([type=submit]), .habitats-website-modules-forms-Index select, .habitats-website-modules-forms-Index textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  background-color: #f9f9f9;
}
.habitats-website-modules-forms-Index h5 {
  font-size: 0.85rem;
}
.habitats-website-modules-forms-Index .input-group-value {
  width: 100%;
}
@media (min-width: 768px) {
  .habitats-website-modules-forms-Index .radio-custom,
  .habitats-website-modules-forms-Index .checkbox-custom {
    margin-left: 18px;
  }
  .habitats-website-modules-forms-Index .radio-custom input,
  .habitats-website-modules-forms-Index .checkbox-custom input {
    margin-top: -7px;
  }
  .habitats-website-modules-forms-Index .radio-custom label,
  .habitats-website-modules-forms-Index .checkbox-custom label {
    margin-left: 5px;
  }
}
@media (max-width: 767.98px) {
  .habitats-website-modules-forms-Index .radio-custom .form-control,
  .habitats-website-modules-forms-Index .checkbox-custom .form-control {
    height: 15px !important;
    padding: 0;
    margin-top: 5px;
  }
}
.habitats-website-modules-forms-Index .btn {
  border-radius: 0;
  background: #1a7f66;
  border-color: #1a7f66;
  font-family: Arial Black, "Archivo Black", sans-serif;
  text-transform: uppercase;
}

.habitats-website-modules-guestclass-Index {
  position: relative;
}
.habitats-website-modules-guestclass-Index form {
  position: relative;
}
.habitats-website-modules-guestclass-Index form .generalConditions {
  position: absolute;
  top: 10px;
  right: 27px;
  text-decoration: none;
  display: flex;
  color: black;
}
.habitats-website-modules-guestclass-Index form .generalConditions .question {
  margin-left: 5px;
  background: #6cb841;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}
.habitats-website-modules-guestclass-Index form .generalConditions .question:active, .habitats-website-modules-guestclass-Index form .generalConditions .question:focus, .habitats-website-modules-guestclass-Index form .generalConditions .question:hover {
  text-decoration: none;
  background: #569234;
}
.habitats-website-modules-guestclass-Index .item {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px solid #ececec;
}
.habitats-website-modules-guestclass-Index .item .head {
  display: block;
  background: #6cb841;
  padding: 5px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.habitats-website-modules-guestclass-Index .item h4 {
  color: white;
  margin: 5px 0;
  font-weight: 800;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.habitats-website-modules-guestclass-Index .item a:active, .habitats-website-modules-guestclass-Index .item a:focus, .habitats-website-modules-guestclass-Index .item a:hover {
  text-decoration: none;
}
.habitats-website-modules-guestclass-Index .item .content {
  display: grid;
  grid-template-rows: 150px 1fr auto;
  width: 100%;
  height: 100%;
}
.habitats-website-modules-guestclass-Index .item .content p {
  margin: 15px 0 5px;
  padding: 15px;
}
.habitats-website-modules-guestclass-Index .item .content .btn {
  margin: 0 auto 15px;
  padding: 5px 25px;
}

.habitats-website-modules-guestclass-Show .g-recaptcha {
  margin-left: auto;
  display: table;
  margin-bottom: 0.5rem;
}

.habitats-website-modules-news-Index {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-news-Index {
    margin-top: 1rem;
  }
}
.habitats-website-modules-news-Index .newsGrid {
  display: grid;
  margin-bottom: 4rem;
  margin-top: 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-news-Index .newsGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .habitats-website-modules-news-Index .newsGrid {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
  }
}
.habitats-website-modules-news-Index .newsGrid .item {
  display: grid;
  grid-template-areas: "date information" "readmore information";
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 30px;
  grid-gap: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;
  padding: 10px;
}
.habitats-website-modules-news-Index .newsGrid .item:hover {
  background: #F4F4F4;
}
.habitats-website-modules-news-Index .newsGrid .item .square-padding {
  padding: 10px !important;
}
.habitats-website-modules-news-Index .newsGrid .item .square {
  grid-area: date;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #b9deff;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.habitats-website-modules-news-Index .newsGrid .item .square img {
  border-radius: 5px;
}
.habitats-website-modules-news-Index .newsGrid .item .square .day {
  font-size: 35px;
  line-height: 1em;
  padding-top: 5px;
}
.habitats-website-modules-news-Index .newsGrid .item .button {
  grid-area: readmore;
}
.habitats-website-modules-news-Index .newsGrid .item .button > * {
  display: block;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
}
.habitats-website-modules-news-Index .newsGrid .item .information {
  grid-area: information;
  overflow: hidden;
}
.habitats-website-modules-news-Index .newsGrid .item .information h4 {
  text-transform: initial;
  margin-bottom: 0;
}
.habitats-website-modules-news-Index .newsGrid .item .information p {
  color: #888888;
  font-weight: 400;
}

.habitats-website-modules-news-Show {
  margin-top: 2.5rem;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-news-Show {
    margin-top: 1rem;
  }
}
.habitats-website-modules-news-Show .informationContainer {
  margin-bottom: 2rem;
}

.habitats-website-modules-teachingmaterial-Index {
  position: relative;
}
.habitats-website-modules-teachingmaterial-Index form {
  position: relative;
}
.habitats-website-modules-teachingmaterial-Index form .generalConditions {
  position: absolute;
  top: 10px;
  right: 27px;
  text-decoration: none;
  display: flex;
  color: black;
}
.habitats-website-modules-teachingmaterial-Index form .generalConditions .question {
  margin-left: 5px;
  background: #6cb841;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}
.habitats-website-modules-teachingmaterial-Index form .generalConditions .question:active, .habitats-website-modules-teachingmaterial-Index form .generalConditions .question:focus, .habitats-website-modules-teachingmaterial-Index form .generalConditions .question:hover {
  text-decoration: none;
  background: #569234;
}
.habitats-website-modules-teachingmaterial-Index .item {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
}
.habitats-website-modules-teachingmaterial-Index .item .head {
  display: block;
  background: #6cb841;
  padding: 5px 15px;
}
.habitats-website-modules-teachingmaterial-Index .item .head h4 {
  color: white;
  margin: 5px 0;
  font-weight: 800;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.habitats-website-modules-teachingmaterial-Index .item a:active, .habitats-website-modules-teachingmaterial-Index .item a:focus, .habitats-website-modules-teachingmaterial-Index .item a:hover {
  text-decoration: none;
}
.habitats-website-modules-teachingmaterial-Index .item .content {
  display: grid;
  grid-template-rows: 150px 1fr auto;
  width: 100%;
  height: 100%;
}
.habitats-website-modules-teachingmaterial-Index .item .content p {
  margin: 15px 0 5px;
  padding: 15px;
}
.habitats-website-modules-teachingmaterial-Index .item .content .btn {
  margin: 0 auto 15px;
  padding: 5px 25px;
}

.habitats-website-modules-teachingmaterial-Show .item h2, .habitats-website-modules-teachingmaterial-Show .item .h2 {
  font-weight: 800;
}
.habitats-website-modules-teachingmaterial-Show .item .reservation {
  border: 1px solid #ececec;
}
.habitats-website-modules-teachingmaterial-Show .item .reservation .head {
  background: #6cb841;
  padding: 5px 15px;
  color: white;
  font-size: 26px;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .habitats-website-modules-teachingmaterial-Show .item .reservation .head {
    font-size: 18px;
  }
}
.habitats-website-modules-teachingmaterial-Show .item .reservation .content {
  padding: 15px;
}
.habitats-website-modules-teachingmaterial-Show .item .reservation .content .information ol li span {
  color: #6cb841;
}