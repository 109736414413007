.common-canvas-blocks-custom-subpages-Block {
	position: relative;

	.subpagesGrid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 2rem;

		@include mb-down(sm) {
			grid-template-columns: repeat(1, 1fr) !important;
		}

		@media (max-width: 1024px) {
			margin: 1rem 0;
			grid-template-columns: repeat(2, 1fr);
		}

		a {
			text-decoration: none;

			.item {
				.img-bg-wrapper {
					height: 210px;
				}

				.information {
					h4 {
						background: $color-primary;
						color: white;
						text-decoration: none;
						padding: 8px 15px;
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
						text-transform: initial;
						text-align: center;
					}
				}
			}
		}
	}
}