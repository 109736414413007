.habitats-website-modules-forms-Index {

      input:not([type="submit"]), select, textarea {
          padding: 10px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          width: 100%;
          background-color: #f9f9f9;
      }

    h5 {
        font-size: .85rem;
    }

    .input-group-value {
        width: 100%;
    }

    .radio-custom,
    .checkbox-custom {
        @include mb-up(md) {
            margin-left: 18px;

            input {
                margin-top: -7px;
            }

            label {
                margin-left: 5px;
            }
        }

        @include mb-down(sm){
            .form-control {
                height: 15px !important;
                padding: 0;
                margin-top: 5px;
            }
        }
    }

    .btn {
        border-radius: 0;
        background: #1a7f66;
        border-color: #1a7f66;
        font-family: Arial Black, 'Archivo Black', sans-serif;
        text-transform: uppercase;
    }
}