.common-form-inputs-ItemSelector {

	.item {
		position: relative;

		&.disabled {
			cursor: not-allowed;
			opacity: .5;
		}

		label {

			border: 1px solid #c4d8f8;
			border-radius: 5px;
			box-shadow: 0 0 1px 0 transparent, 0 0.25rem 12px 0 transparent;
			transition: border-color .3s, box-shadow .3s;
			user-select: none;

			&:before {
				left: auto;
				right: 15px;
				top: calc(50% - 17px / 2);
			}

			&:after {
				left: auto;
				right: calc(15px + 5px);
				top: calc(50% - 17px / 2 + 5px);
			}

			.icon {
				font-size: 30px;
			}

			img {
				max-height: 30px;
				max-width: 30px;
			}

		}

		input:checked + label,
		label:hover {
			border-color: #0e8fff;
			box-shadow: 0 2px 6px rgba(0,0,0,0.2);
		}

	}
}