// TODO To document.
.img-bg-wrapper {
	overflow: hidden;
	position: relative;
	> img {
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		//z-index: -1;
	}
}